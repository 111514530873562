<template>
    <div>
        <v-container fluid>
            <v-row align="center">
                <v-col>
                    <v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbs"></v-breadcrumbs>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card>
                            <v-tabs show-arrows v-model="tab">
                                <v-tabs-slider></v-tabs-slider>
                                <v-tab> <v-icon left>mdi-calendar-check</v-icon> Actividades</v-tab>
                                <v-tab> <v-icon left>mdi-youtube</v-icon>Material de Apoyo</v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <ActividadesMaestro></ActividadesMaestro>
                                </v-tab-item>
                                <v-tab-item>
                                    <MaterialMaestro></MaterialMaestro>
                                </v-tab-item>
                            </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

    import ActividadesMaestro from '@/components/cursos/ActividadesMaestro'
    import MaterialMaestro from '@/components/cursos/MaterialMaestro'

    export default {
        components: {
            ActividadesMaestro,
            MaterialMaestro
        },
        data(){
            return{
                breadcrumbs: [
                    {
                        text: 'Cursos',
                        href: '#/home/cursos_maestro',
                    },
                    {
                        text: 'Curso',
                        active: true
                    },
                ],
                tab: 0,
                curso: {}
            }
        },
        methods: {

            detalle_curso(){

                let data = {

                    id: this.$route.params.id

                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_curso", data)
                .then((response) => {
                    
                    this.curso = response.data
                    this.breadcrumbs[1].text = this.curso.nombre

                })

            }

        },
        mounted(){

            this.detalle_curso()

        }
    }
</script>