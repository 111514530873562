<template>
    <div>
            <section id="about-me">
                <div class="py-12"></div>

                <v-container class="text-center">
                    <h2 class="display-2 font-weight-bold mb-3">
                        LICEO DE CIENCIAS TÉCNICAS ZONA 6
                    </h2>

                    <v-responsive
                        class="mx-auto mb-8"
                        width="56"
                    >
                        <v-divider class="mb-1"></v-divider>

                        <v-divider></v-divider>
                    </v-responsive>

                    <v-responsive
                        class="mx-auto title font-weight-light mb-8"
                        max-width="720"
                    >
                        1ra Calle 13-59 Zona 6
                        <br>
                        Tel. 2288-1033
                    </v-responsive>
                </v-container>

               
            </section>
            <section
                id="features"
                class="grey lighten-3"
            >
                <div class="py-12"></div>

                <v-container class="text-center">
                <h2 class="display-2 font-weight-bold mb-3">SOBRE NOSOTROS</h2>

                <v-responsive
                    class="mx-auto mb-12"
                    width="56"
                >
                    <v-divider class="mb-1"></v-divider>

                    <v-divider></v-divider>
                </v-responsive>

                <v-row>
                    <v-col
                    v-for="({ icon, title, text }, i) in features"
                    :key="i"
                    cols="12"
                    md="6"
                    >
                    <v-card
                        class="py-12 px-4"
                        color="grey lighten-5"
                        flat
                        min-height="410"
                    >
                        <v-theme-provider dark>
                        <div>
                            <v-avatar
                            color="primary"
                            size="88"
                            >
                            <v-icon
                                large
                                v-text="icon"
                            ></v-icon>
                            </v-avatar>
                        </div>
                        </v-theme-provider>

                        <v-card-title
                        class="justify-center font-weight-black text-uppercase"
                        v-text="title"
                        ></v-card-title>

                        <v-card-text
                        class="subtitle-1"
                        v-text="text"
                        >
                        </v-card-text>
                    </v-card>
                    </v-col>
                </v-row>
                </v-container>

                <div class="py-12"></div>
            </section>

            <section>
                <div class="py-12"></div>
                 <v-container class="text-center">
                    <h2 class="display-2 font-weight-bold mb-3">JORNADAS</h2>

                    <v-responsive
                        class="mx-auto mb-8"
                        width="56"
                    >
                        <v-divider class="mb-1"></v-divider>

                        <v-divider></v-divider>
                    </v-responsive>

                    <v-responsive
                        class="mx-auto title font-weight-light mb-8"
                    >
                        <v-row align="center">
                            <v-col cols="6">
                                <v-img src="https://27mi124bz6zg1hqy6n192jkb-wpengine.netdna-ssl.com/wp-content/uploads/2020/05/10-Teaching-Strategies-to-Help-Students-Listen-scaled.jpg"></v-img>
                            </v-col>
                            <v-col cols="6">
                                <v-row align="center" justify="center" dense>
                                    <v-col cols="12">
                                        <h4>PLAN DIARIO</h4>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Básicos con Orientación en Computación
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Bachillerato en Diseño Gráfico (Jornada Matutina)
                                                </v-list-item-title>
                                               <v-list-item-title>
                                                    Bachillerato en Computación con Orientación Comercial (Diplomado en Manteimiento, Ensamble y Reparación de Computadoras)
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Bachillerato en Computación (Diplomado en Medicina, Nutrición y Naturopatia)
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Perito Contador con Orientación en Computación (Diplomado en Auditoria)
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Perito en Administración de Empresas
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Secretariado y Oficinista
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12">
                                        <h4>JORNADA FIN DE SEMANA - DÍAS SABADOS</h4>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Primaria Acelerada
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Básicos
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Básicos por Madurez (Edad mínima 18 años)
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Bachillerato en Ciencias y Letras por Madurez (Edad mínima 18 años)
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Perito Contador
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Bachillerato en Computación con Orientación Comercial
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Secretariado y Oficinista (Diploma a su elección)
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-responsive>
                </v-container>

            </section>
    </div>
        
</template>

<script>
    export default {
        data(){
            return{
                features: [
                    {
                        icon: 'mdi-bullseye-arrow',
                        title: 'Misión',
                        text: 'El Liceo de Ciencias Técnicas es una institución educativa al servicio de la sociedad.  Su función abarca todos los niveles de educación tanto formar como informal, preparando académica y culturalmente estudiantes, formando valores, tales como confianza, respeto y deseo de superación, contanto con personal calificado y tecnología, a la vanguardia de lo que el estudiante necesita',
                    },
                    {
                        icon: 'mdi-eye',
                        title: 'Visión',
                        text: 'Formar integralmente al educando, brindando apoyo al desarrollo de nuestra comunidad, en un prestigioso colegio a bajo costo, educando con amistad, amor y respecto.',
                    }
                ]
            }
        }
    }
</script>

<style>

</style>