<template>
    <div>
        <v-container fluid>

            <v-list class="mt-4">
                <v-list-group
                    v-for="item in unidades"
                    :key="item.id"
                    v-model="item.active"
                    :prepend-icon="item.icono"
                    no-action
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="headline">Unidad {{ item.id}}</v-list-item-title>

                        </v-list-item-content>
                    </template>

                    <v-container fluid>

                        <v-row class="mt-2">
                            <v-col cols="12" md="6" v-for="actividad in item.tareas" :key="actividad.id">
                                <v-card outlined>
                                    <v-card-title class="headline">
                                        {{ actividad.nombre }}
                                    </v-card-title>

                                    <v-card-subtitle class="pb-0">
                                        <small>Fecha de Publicación: {{ actividad.created_at }}</small>
                                    </v-card-subtitle>

                                    <v-card-subtitle class="pt-0 mt-0">
                                        <small>
                                            <strong>Fecha de Entrega: {{ actividad.fecha_entrega }}</strong>
                                        </small>
                                    </v-card-subtitle>

                                    <v-card-subtitle min-height="200" class="pt-0">
                                        <p class="mb-2">
                                            {{ actividad.descripcion }}
                                        </p>
                                    </v-card-subtitle>

                                    <v-card-subtitle class="pt-0">
                                        <v-row dense>
                                            <v-col cols="6">
                                                <small>Adjuntos: </small>
                                                <br>
                                                <v-chip :color="actividad.adjuntos.length > 0 ? 'primary' :  'default'" small label>
                                                    {{ actividad.adjuntos.length }} Documentos
                                                </v-chip>
                                            </v-col>
                                            <v-col cols="6">
                                                <small>Puntos: </small>
                                                <br>
                                                <h4>{{ actividad.punteo }}</h4>
                                            </v-col>
                                        </v-row>
                                    </v-card-subtitle>

                                    <v-divider class="mb-2"></v-divider>
                                    <v-card-actions>
                                        <v-btn @click="modal_editar(actividad.id)" text :color="actividad.entrega.length > 0 ? 'success' : 'primary'">
                                            {{ actividad.entrega.length > 0 ? 'ENTREGADO' : 'ENTREGAR' }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>


                    </v-container>

                </v-list-group>

         </v-list>

            
            <Modal @closeModal="modalShow = false" :titleModal="titleModal" :sizeModal="sizeModal" :modalShow="modalShow" ref="modal">
                <template #form-modal>
                    <Form :idItem="idItem" :enableEdit="editMode" @updateTable="obtener_datos" @closeModal="modalShow = false"></Form>
                </template>
            </Modal>

        </v-container>
    </div>
</template>

<script>
    import Modal from "@/components/Modal";
    import Form from "@/components/actividades/FormEntregar";

    import Swal from 'sweetalert2'

    export default {
        components: {
            Modal,
            Form
        },
        data(){
            return{
                actividades: [],
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                editMode: false,
                idItem: null,
                unidades: []
            }
        },
        methods: {

            obtener_datos(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))
                    
                let data = {

                    curso_id: this.$route.params.id,
                    alumno_id: usuario.id_persona

                }

                this.axios.post(process.env.VUE_APP_API_URL + "actividades_curso_alumno", data)
                .then((response) => {
                    
                    console.log(response.data);
                    this.unidades = response.data

                })

            },
            mostrarModal(){

                this.titleModal = "Agregar Actividad"
                this.sizeModal = "800"
                this.editMode = false
                this.idItem = null
                this.modalShow = true                

            },
            modal_editar(id){

                this.titleModal = "Entregar Actividad"
                this.sizeModal = "800"
                this.editMode = true
                this.idItem = id
                this.modalShow = true

            },
            eliminar(id){

                let data = {

                    id: id

                }

                Swal.fire({
                    title: '¿Está seguro?',
                    html: "<strong>Precaución: </strong>Si se elimina la actividad ya no se podrá acceder a la información de la misma!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, ELIMINAR!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        this.axios.post(process.env.VUE_APP_API_URL + "eliminar_actividad", data)
                        .then((response) => {

                            if (response.data) {
                                
                                Swal.fire({
                                    title: 'Excelente!',
                                    text: 'La actividad a sido eliminada exitosamente',
                                    icon: 'success',
                                }).then(() => {

                                    this.obtener_datos()

                                })

                            }
                        
                        })

                    }
                })

            }

        },
        mounted(){

            this.obtener_datos()

        }
    }
</script>