import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)

import EnlargeableImage from '@diracleo/vue-enlargeable-image';
 
Vue.use(EnlargeableImage)

import { firebase } from '@firebase/app'
import 'firebase/messaging'

Vue.config.productionTip = false

firebase.initializeApp({
  apiKey: "AIzaSyCQ1MGzIGjXlMAbu6PHg1clhinJUF2BJ5E",
  authDomain: "corporacion-educativa-superior.firebaseapp.com",
  projectId: "corporacion-educativa-superior",
  storageBucket: "corporacion-educativa-superior.appspot.com",
  messagingSenderId: "267033650297",
  appId: "1:267033650297:web:b486291fea1b165ff420a8",
  measurementId: "G-DTFM523EML",
})

navigator.serviceWorker.register('firebase-messaging-sw.js', {scope: 'firebase-cloud-messaging-push-scope'})
.then((registration) => {
  const messaging = firebase.messaging()
  messaging.useServiceWorker(registration)
})
.catch(err => {
  console.log(err);
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
