<template>
    <div>
        <v-row>
            <v-col>
                <v-list>
                    <v-list-group
                        v-for="item in alumnos"
                        :key="item.id"
                        v-model="item.active"
                        prepend-icon="mdi-circle"
                        no-action
                    >
                        <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title >
                                {{ item.nombre }} {{ item.segundo_nombre }} {{ item.apellido }} {{ item.segundo_apellido }}
                            </v-list-item-title>
                        </v-list-item-content>
                        </template>

                        <!-- <v-list-item
                        v-for="child in item.items"
                        :key="child.title"
                        >
                        <v-list-item-content>
                            <v-list-item-title v-text="child.title"></v-list-item-title>
                        </v-list-item-content>
                        </v-list-item> -->
                        <v-card v-if="item.entrega" class="mt-4 mb-4" outlined>
               
                            <v-card-text class="pt-0 mt-0">
                                
                                <v-row class="mt-4">
                                    <v-col cols="4">
                                        <v-alert type="success" dark dense text>
                                            Actividad Entregada    
                                        </v-alert> 
                                    </v-col>
                                    <v-col v-if="item.entrega.archivo">
                                        <a :href="api_url + item.entrega.id">Descargar 
                
                                        </a>
                                    </v-col>
                                </v-row>
                                
                            </v-card-text>
                            <v-card-text class="mt-0 pt-0">
                                <v-row align="center">
                                    <v-col cols="9">
                                        <v-textarea v-model="item.entrega.observaciones" hide-details outlined rows="3" label="Observaciones"></v-textarea>
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="item.entrega.calificacion" hide-details outlined label="Punteo"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row class="mt-4" align="center" justify="center">
                                    <v-col align="center" cols="12">
                                        
                                        <v-btn
                                            color="primary"
                                            class="ml-2"
                                            @click="calificar(item)"
                                        >
                                            GUARDAR CAMBIOS
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card v-if="!item.entrega" class="mt-4 mb-4" outlined>
               
                            <v-card-text class="pt-0 mt-0">
                                
                                <v-row class="mt-4">
                                    <v-col cols="4">
                                        <v-alert type="warning" dark dense text>
                                            Actividad Pendiente    
                                        </v-alert> 
                                    </v-col>
                                
                                </v-row>
                                
                            </v-card-text>
                            <v-card-text class="mt-0 pt-0">
                                <v-row align="center">
                                    <v-col cols="9">
                                        <v-textarea v-model="item.entrega_sin.observaciones" hide-details outlined rows="3" label="Observaciones"></v-textarea>
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="item.entrega_sin.calificacion" hide-details outlined label="Punteo"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row class="mt-4" align="center" justify="center">
                                    <v-col align="center" cols="12">
                                        
                                        <v-btn
                                            color="primary"
                                            class="ml-2"
                                            @click="calificar_sin_entrega(item)"
                                        >
                                            GUARDAR CAMBIOS
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                    </v-list-group>
                </v-list>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        props: {
            enableEdit: Boolean,
            idItem: Number
        },
        data(){
            return{
                alumnos: [],
                api_url: process.env.VUE_APP_API_URL + 'descargar_archivo/',
                entrega: {
                    observaciones: null,
                    calificacion: null
                }
            }
        },
        methods: {

            obtener_detalle(){

                let data = {

                    id: this.idItem,

                }

                this.axios.post(process.env.VUE_APP_API_URL + "alumnos_actividad", data)
                .then((response) => {
                    
                    this.alumnos = response.data

                })


            },
            calificar(entrega){

                this.axios.post(process.env.VUE_APP_API_URL + "calificar", entrega.entrega)
                // eslint-disable-next-line no-unused-vars
                .then((response) => {
                    
                    Swal.fire({
                        title: 'Excelente!',
                        text: 'La actividad a sigo actualizada exitosamente',
                        icon: 'success',
                    }).then(() => {
                                                        
                        this.obtener_detalle()

                    })

                })

            },
            calificar_sin_entrega(item){

                if (item.entrega_sin.calificacion) {
                    
                    const data = {
                        calificacion: item.entrega_sin.calificacion,
                        observaciones: item.entrega_sin.observaciones,
                        tarea_id: item.tarea.id,
                        alumno_id: item.id
                    }

                    this.axios.post(process.env.VUE_APP_API_URL + "calificar_sin_entrega", data)
                    // eslint-disable-next-line no-unused-vars
                    .then((response) => {
                        
                        Swal.fire({
                            title: 'Excelente!',
                            text: 'La actividad a sigo actualizada exitosamente',
                            icon: 'success',
                        }).then(() => {
                                                            
                            this.obtener_detalle()

                        })

                    })

                }

            }

        },
        watch: {

            idItem: function(){

                this.obtener_detalle()

            }
        },
        mounted(){

            this.obtener_detalle()

        }

    }
</script>