<template>
    <div>

        <v-container fluid>

            <v-row >
                <v-col
                    md="4"
                    cols="12"
                    v-for="(anuncio, key) in anuncios"
                    :key="key"
                >	

                    <v-card min-height="310" class="flexcard">
                        <v-card-title
                            class="mb-2"
                        >
                            <h3>
                                {{ anuncio.nombre }}
                            </h3>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-card-subtitle>
                                        {{ anuncio.descripcion }}
                                    </v-card-subtitle>
                                </v-col>
                                <v-col md="4" cols="12">
                                    <v-img height="165" contain  :src="anuncio.portada ? api + anuncio.portada : '/img/megaphone.png'">

                                        <v-card-title v-if="anuncio.portada" class="align-center fill-height justify-center" primary-title>
                                            <v-btn @click="agrandar(anuncio.portada)" icon x-large>
                                                <v-icon>
                                                    mdi-magnify-plus
                                                </v-icon>
                                            </v-btn>
                                        </v-card-title>
                                    </v-img>
                                </v-col>
                            </v-row>

                        </v-card-text>
                        
                        <v-divider class="mb-2"></v-divider>

                        <v-card-actions class="card-actions">
                                
                            <v-btn
                                icon
                                small
                                v-if="anuncio.adjunto"
                                :href="api_url + anuncio.id"
                            >
                                <v-icon>
                                    mdi-download
                                </v-icon>
                            </v-btn>

                            

                            <v-spacer></v-spacer>
                            <small>
                                Fecha de Publicación: {{ anuncio.created_at }}
                            </small>
                        </v-card-actions>


                    </v-card>
                </v-col>
            </v-row>

            <Modal @closeModal="modalShowImg = false" :modalShow="modalShowImg" :titleModal="titleModal" :sizeModal="sizeModal">
                <template #form-modal>
                    <v-img height="500" contain :src="api + imgLarge"></v-img>
                </template>
            </Modal>

        </v-container>
        
    </div>
</template>

<script>

    import Modal from '@/components/Modal'

    import Swal from 'sweetalert2'

    export default {
        components: {
           Modal
        },
        data(){
            return{
                data_url: process.env.VUE_APP_API_URL + "obtener_anuncios",
                api_url: process.env.VUE_APP_API_URL + 'descargar_anuncio/',
                busqueda: null,
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                enableEdit: false,
                idItem: null,
                anuncios: [],
                api: process.env.VUE_APP_API_URL,
                modalShowImg: false,
                imgLarge: null 
            }
        },
        methods: {

            obtener_datos(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {
                    sede_id: usuario.sede_id
                }

                this.axios.post(this.data_url, data)
                .then((response) => {
                    
                    console.log(response.data)
                    this.anuncios = response.data

                })

            },
            mostrarModal(){

                this.titleModal = "Crear Anuncio"
                this.sizeModal = "1200"
                this.enableEdit = false
                this.idItem = null
                this.modalShow = true

            },
            closeModal(){

                this.modalShow = false

            },
            mostrarEditar(id){
                
                this.titleModal = "Editar Anuncio"
                this.sizeModal = "1200"
                this.enableEdit = true
                this.idItem = id
                this.modalShow = true

            },
            eliminar(id){

                let data = {

                    id_alumno: id

                }

                Swal.fire({
                    title: '¿Está seguro?',
                    text: "Una vez eliminado no se podrá recuperar!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, ELIMINAR!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        this.axios.post(process.env.VUE_APP_API_URL + "eliminar_alumno", data)
                        .then((response) => {

                            if (response.data) {
                                
                                Swal.fire({
                                    title: 'Excelente!',
                                    text: 'El alumno a sido eliminado exitosamente',
                                    icon: 'success',
                                }).then(() => {

                                    this.obtener_datos()

                                })

                            }
                        
                        })

                    }
                })

            },
            agrandar(img){

                this.sizeModal = "500"
                this.modalShowImg = true
                this.imgLarge = img

            }

        },
        mounted(){

            this.obtener_datos()

        }     
    }
</script>