<template>
    <div>

        <v-container fluid>
            
            <v-row dense align="center">
                <v-col>
                    <v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbs"></v-breadcrumbs>
                </v-col>
            </v-row>

             <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="grado.nombre" readonly hide-details autocomplete="off" label="Grado" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-text-field v-model="grado.nivel" readonly hide-details autocomplete="off" label="Nivel" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-text-field v-model="grado.jornada" readonly hide-details autocomplete="off" label="Jornada" outlined></v-text-field>
                                </v-col>

                            </v-row>

                        </v-card-text>

                        <v-card-text>
                            <v-row align="center">
                                <v-col class="pt-0">
                                    <small>Fecha de registro: {{ !grado.created_at ? 'No disponible' : grado.created_at }}</small>
                                </v-col>
                                <v-col align="end">
                                   
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                </v-col>
             </v-row>

             <v-row>
                <v-col>

                    <v-card>
                        <v-tabs v-model="tab">
                            <v-tab>Cursos</v-tab>
                            <v-tab>Pagos</v-tab>
                        </v-tabs>
                        
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <Cursos></Cursos>
                            </v-tab-item>

                            <v-tab-item>
                                <Pagos></Pagos>
                            </v-tab-item>
                           
                        </v-tabs-items>

                    </v-card>

                </v-col>
            </v-row>

        </v-container>
    </div>
</template>

<script>

    import Cursos from '@/components/grados_pred/Cursos'
    import Pagos from '@/components/grados_pred/Pagos'

    export default {

        components: {
            Cursos,
            Pagos
        },
        data(){

            return{
                breadcrumbs: [
                    {
                        text: 'Administración',
                        href: '#/home/administracion',
                    },
                    {
                        text: 'Grados',
                        href: '#/home/administracion/grados',
                    },
                    {
                        text: 'Detalle de Grado',
                        active: true
                    },
                ],
                grado: {},
                tab: 0,
                modalShow: false
            }

        },
        methods: {

            obtener_detalle(){

                let data = {

                    id: this.$route.params.id

                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_grado_pred", data)
                .then((response) => {
                    
                    console.log(response.data);

                    this.grado = response.data

                })

            }

        },
        mounted(){

            this.obtener_detalle()

        }

    }
</script>