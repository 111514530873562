<template>
    <div class="mt-4">
        <v-form @submit.prevent="!enableEdit ? registrar() : editar()" v-model="valid" ref="form">
            <v-row>
                <v-col cols="8">
                    <v-text-field v-model="actividad.nombre" :rules="[v => !!v || 'Campo obligatorio!']" hide-details autocomplete="off" label="Nombre" outlined></v-text-field>
                </v-col>

                <v-col cols="4">
                    <v-text-field v-model="actividad.fecha_entrega" hide-details autocomplete="off" label="Fecha de Entrega" outlined></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-textarea rows="3" v-model="actividad.descripcion" :rules="[v => !!v || 'Campo obligatorio!']" hide-details autocomplete="off" label="Descripción" outlined></v-textarea>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="actividad.punteo" :rules="[v => !!v || 'Campo obligatorio!']" hide-details autocomplete="off" label="Punteo" type="number" outlined></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-select :items="bimestres" v-model="actividad.bimestre" :rules="[v => !!v || 'Campo obligatorio!']" hide-details autocomplete="off" label="Bimestre" type="number" outlined></v-select>
                </v-col>
            </v-row>

            <v-card v-if="actividad.adjuntos" class="mt-4" outlined>
                <v-card-title>Documentos Adjuntos </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Documento
                                </th>
                                <th class="text-left">
                                    Acción
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(adjunto, index) in actividad.adjuntos"
                                :key="index"
                            >
                            <td>{{ adjunto.nombre_archivo }}
                                <v-chip v-if="adjunto.delete" x-small color="red" dark>
                                    ELIMINAR
                                </v-chip>
                            </td>
                            <td>
                            
                                    <v-btn :href="api_url + adjunto.id" icon color="primary">
                                        <v-icon>
                                            mdi-download
                                        </v-icon>
                                    </v-btn>
                                
                                <v-btn @click="eliminar_adjunto(adjunto)" icon color="red">
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>

            <v-card class="mt-4" outlined>
                <v-card-title>Adjuntar Documentos </v-card-title>
                <v-card-text>
                    <v-file-input
                        label="Seleccione un o varios archivo"
                        v-model="actividad.archivos"
                        chips
                        multiple
                    ></v-file-input>
                </v-card-text>
            </v-card>

            <v-row class="mt-4" align="center" justify="center">
                <v-col align="center" cols="12">
                    <v-btn
						color="secondary"
						@click="() => { this.$emit('closeModal') }"
					>
						Cancelar
					</v-btn>

					<v-btn
						color="primary"
                        class="ml-2"
						type="submit"
					>
						Aceptar
					</v-btn>
                </v-col>
            </v-row>

        </v-form>
    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        props: {
            enableEdit: Boolean,
            idItem: Number
        },
        data(){

            return{
                actividad: {
                    nombre: null,
                    descripcion: null,
                    fecha_entrega: null,
                    punteo: null,
                    bimestre: null,
                    archivos: []
                },
                valid: true,
                api_url: process.env.VUE_APP_API_URL + 'descargar_adjunto/',
                bimestres: [1,2,3,4]
            }

        },
        methods: {

            registrar(){

                this.$refs.form.validate()

                if (this.valid) {

                    let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                    this.actividad.catedratico_id = usuario.id_persona
                    this.actividad.curso_id = this.$route.params.id

                    this.axios.post(process.env.VUE_APP_API_URL + "registrar_actividad", this.actividad)
                    .then((response) => {
                        
                        if (response.data) {
                            
                            this.actividad.archivos.forEach(archivo => {
                                
                                let formData = new FormData();
                                formData.append('files', archivo);
                                formData.append('tarea_id', response.data.id);

                                this.axios.post(process.env.VUE_APP_API_URL + "adjuntar_archivos", formData)
                                // eslint-disable-next-line no-unused-vars
                                .then((response) => {

                                    Swal.fire({
                                        title: 'Excelente!',
                                        text: 'La actividad a sigo registrada exitosamente',
                                        icon: 'success',
                                    }).then(() => {
                                                                        
                                        this.$emit('closeModal')
                                        this.$emit('updateTable') 

                                    })
                                })

                            });

                            Swal.fire({
                                title: 'Excelente!',
                                text: 'La actividad a sigo registrada exitosamente',
                                icon: 'success',
                            }).then(() => {
                                                                
                                this.$emit('closeModal')
                                this.$emit('updateTable') 

                            })

                        }

                    })

                }

            },
            editar(){

                this.$refs.form.validate()

                if (this.valid) { 

                    this.axios.post(process.env.VUE_APP_API_URL + "editar_actividad", this.actividad)
                    .then((response) => {
                        
                        console.log(response.data)

                        if (response.data) {
                        
                            this.actividad.archivos.forEach(archivo => {
                            
                                let formData = new FormData();
                                formData.append('files', archivo);
                                formData.append('tarea_id', response.data.id);

                                this.axios.post(process.env.VUE_APP_API_URL + "adjuntar_archivos", formData)
                                // eslint-disable-next-line no-unused-vars
                                .then((response) => {

                                    
                                })

                            });

                        }

                        Swal.fire({
                            title: 'Excelente!',
                            text: 'La actividad a sido editada exitosamente',
                            icon: 'success',
                        }).then(() => {

                            this.$emit('closeModal')
                            this.$emit('updateTable') 

                        })

                    })

                }

            },
            obtener_detalle(){

                let data = {

                    id: this.idItem

                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_actividad", data)
                .then((response) => {
                    
                    this.actividad = response.data
                    this.actividad.archivos = []

                })


            },
            eliminar_adjunto(adjunto){

                adjunto.delete = !adjunto.delete

            }

        },
        watch: {

            idItem: function(val){

                if (val) {
                    
                    this.obtener_detalle()

                }

            }

        },
        mounted(){

            if (this.enableEdit) {
                
                this.obtener_detalle()

            }

        }

    }
</script>