<template>
    <div>

        <v-container fluid>

            <v-row align="center">
                <v-col>
                    <v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbs"></v-breadcrumbs>
                </v-col>
            </v-row>

            <v-row dense class="mb-4" align="center">
                <v-col md="4">
                    <v-text-field hide-details v-model="busqueda" label="Buscar..." prepend-inner-icon="mdi-account-search" clearable outlined></v-text-field>
                </v-col>
                <v-col align="end">
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="10"
                        class="elevation-2"
                        :search="busqueda"
                    >
                    
                        <template v-slot:[`item.action`]="data">

                            <v-btn @click="restablecer_clave(data.item.id)" icon xs color="orange darken-1">
                                <v-icon>mdi-lock-open</v-icon>
                            </v-btn>

                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        data(){
            return{
                data_url: process.env.VUE_APP_API_URL + "obtener_usuarios",
                busqueda: null,
                headers: [],
                items: [],
                fab: false,
                breadcrumbs: [
                    {
                        text: 'Administración',
                        href: '#/home/administracion',
                    },
                    {
                        text: 'Usuarios',
                        active: true
                    },
                ]
            }
        },
        methods: {

            obtener_datos(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {
                    sede_id: usuario.sede_id
                }

                this.axios.post(this.data_url, data)
                .then((response) => {
                    
                    console.log(response.data);
                    this.headers = response.data.headers
                    this.items = response.data.items

                })

            },
            restablecer_clave(id){

                Swal.fire({
                    title: '¿Está seguro?',
                    text: "La clave se restablecera al nombre del usuario!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, RESTABLECER!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {

                    if (result.isConfirmed) {

                        let data = {
                            id: id
                        }

                        this.axios.post(process.env.VUE_APP_API_URL + "restablecer_clave", data)
                        .then((response) => {
                            
                            if (response.data) {
                                
                                Swal.fire({
                                    title: 'Excelente!',
                                    text: 'La clave a sido restablecida exitosamente',
                                    icon: 'success',
                                })

                            }
                            
                        })
                    }

                })

            }

        },
        mounted(){

            this.obtener_datos()

        }     
    }
</script>