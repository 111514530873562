<template>
    <div>
        <v-row v-if="!enableEdit">
            <v-col>
                <v-card class="mt-4">
                        <v-card-title>Seleccione un curso</v-card-title>
                        <v-card-text>

                            <v-row dense v-for="curso in cursos" :key="curso.id" align="center" justify="center">
                                <v-col cols="1">
                                     <v-checkbox
                                        v-model="curso.check"
                                        ></v-checkbox>
                                </v-col>
                                <v-col cols="9">
                                    <h3>{{ curso.nombre }}</h3>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn :disabled="!curso.check" @click="curso.mostrar_maestro = true" color="primary" x-small icon>
                                        <v-icon>mdi-account-plus</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col v-if="curso.mostrar_maestro" class="mb-2" cols="12">
                                    <v-select v-model="curso.maestro_id" :items="maestros" item-text="nombre" item-value="id" dense autocomplete="off" hide-details label="Maestro" outlined></v-select>
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>

                            <v-row class="mt-4" align="center" justify="center">
                                <v-col align="center" cols="12">
                                    <v-btn
                                        color="secondary"
                                        @click="() => { this.$emit('closeModal') }"
                                    >
                                        Cancelar
                                    </v-btn>

                                    <v-btn
                                        color="primary"
                                        class="ml-2"
                                        @click="habilitar_cursos()"
                                    >
                                        Aceptar
                                    </v-btn>
                                </v-col>
                            </v-row>
                           
                        </v-card-text>
                    </v-card>
            </v-col>
        </v-row>

        <v-form v-if="enableEdit">
        <v-row class="mt-4" >
            <v-col cols="12">
                <v-text-field v-model="curso.nombre" readonly autocomplete="off" hide-details label="Curso" outlined></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-select v-model="curso.maestro_id" :items="maestros" item-text="nombre" item-value="id" autocomplete="off" hide-details label="Maestro" outlined></v-select>
            </v-col>
        </v-row>

         <v-row class="mt-4" align="center" justify="center">
            <v-col align="center" cols="12">
                <v-btn
                    color="secondary"
                    @click="() => { this.$emit('closeModal') }"
                >
                    Cancelar
                </v-btn>

                <v-btn
                    color="primary"
                    class="ml-2"
                    @click="editar_curso()"
                >
                    Aceptar
                </v-btn>
            </v-col>
        </v-row>
        </v-form>

    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        props: {
            idItem: Number,
            enableEdit: Boolean
        },
        data(){
            return{
                cursos: [],
                maestros: [],
                curso: {}
            }
        },
        methods: {

            obtener_datos(){

                let data = {
                    grado_id: this.$route.params.id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "cursos_habilitar", data)
                .then((response) => {
                    
                    this.cursos = response.data
                    
                })

                // Obtener los maestros disponibles
                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                data = {
                    sede_id: usuario.sede_id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "obtener_maestros", data)
                .then((response) => {
                    
                    this.maestros = response.data
                    
                })

            },
            habilitar_cursos(){

                let data = {
                    cursos: this.cursos,
                    grado_id: this.$route.params.id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "habilitar_cursos", data)
                .then((response) => {
                    
                    if (response.data) {
                            
                        Swal.fire({
                            title: 'Excelente!',
                            text: 'Los cursos han sido habilitados exitosamente',
                            icon: 'success',
                        }).then(() => {

                            this.$emit('closeModal')
                            this.$emit('updateTable') 

                        })

                    }
                    
                })

            },
            obtener_detalle(){

                let data = {

                    id: this.idItem

                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_curso", data)
                .then((response) => {
                    
                    console.log(response.data);
                    this.curso = response.data

                })

            },
            editar_curso(){

                this.axios.post(process.env.VUE_APP_API_URL + "editar_curso", this.curso)
                .then((response) => {
                    
                    if (response.data) {
                        
                        console.log(response.data);
                        
                        Swal.fire({
                            title: 'Excelente!',
                            text: 'El curso a sido editado exitosamente',
                            icon: 'success',
                        }).then(() => {

                            this.$emit('closeModal')
                            this.$emit('updateTable') 

                        })

                    }

                })

            }

        },
        watch: {

            idItem: function(val){

                if (val) {
                    
                    this.obtener_detalle()

                }

            }

        },
        mounted(){

            if (this.enableEdit) {
                
                this.obtener_detalle()

            }

            this.obtener_datos()

        }
    }
</script>