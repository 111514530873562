<template>
    <div>

        <Boleta></Boleta>

    </div>
</template>

<script>

    import Boleta from '@/components/boleta/VistaPrevia'

    export default {
        components: {
            Boleta
        },
        data(){
            return{

            }
        },
        methods: {

        },
        mounted(){

            

        }        
    }
</script>