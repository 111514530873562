<template>
    <div>

        <v-container class="mt-4" fluid>
             <v-row class="mb-4" align="center">
                <v-col md="4">
                    <v-text-field hide-details v-model="busqueda" label="Buscar..." prepend-inner-icon="mdi-account-search" clearable outlined></v-text-field>
                </v-col>
                <v-col align="end">
                    <v-btn @click="mostrar_pagos()" color="success" class="mr-2">PAGOS 
                        <v-icon>mdi-currency-usd</v-icon>
                    </v-btn>
                    <v-btn @click="mostrar_solvencias()" color="info" class="mr-2">SOLVENCIAS 
                        <v-icon>mdi-check-all</v-icon>
                    </v-btn>
                    <!-- <v-btn @click="mostrar_asistencia()" color="primary" class="mr-2">ASISTENCIA 
                        <v-icon>mdi-text-box-check</v-icon>
                    </v-btn> -->
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="10"
                        class="elevation-2"
                        :search="busqueda"
                    >
                    
                        <template v-slot:[`item.action`]="data">

                            <v-btn :to="{ name: 'detalle_alumno', params: {id: data.item.id} }" icon xs color="primary">
                                <v-icon>mdi-account-search</v-icon>
                            </v-btn>

                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

            <Modal @closeModal="closeModal()" :modalShow="modalShow" :titleModal="titleModal" :sizeModal="sizeModal">
                <template #form-modal>
                    <FormPreview :id="id" :url="url"></FormPreview>
                </template>
            </Modal>

        </v-container>

    </div>
</template>

<script>

    import Modal from '@/components/Modal'
    import FormPreview from '@/components/FormPreview'

    export default {
        components: {
            Modal,
            FormPreview
        },
        data(){
            return{
                data_url: process.env.VUE_APP_API_URL + "obtener_alumnos_grado",
                busqueda: null,
                headers: [],
                items: [],
                fab: false,
                breadcrumbs: [
                    {
                        text: 'Administración',
                        href: '#/home/administracion',
                    },
                    {
                        text: 'Usuarios',
                        active: true
                    },
                ],
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                id: null,
                url: null
            }
        },
        methods: {

            obtener_datos(){

                let data = {
                    id_grado: this.$route.params.id
                }

                this.axios.post(this.data_url, data)
                .then((response) => {
                    
                    console.log(response.data);
                    this.headers = response.data.headers
                    this.items = response.data.items

                })

            },
            mostrar_pagos(){

                this.titleModal = "Pagos"
                this.sizeModal = "1200"
                this.id = parseInt(this.$route.params.id)
                this.url = 'reporte_pagos'
                this.modalShow = true

            },
            mostrar_solvencias(){

                this.titleModal = "Solvencias"
                this.sizeModal = "1200"
                this.id = parseInt(this.$route.params.id)
                this.url = 'reporte_solvencias'
                this.modalShow = true

            },
            mostrar_asistencia(){

                this.modalShow = true
                this.titleModal = "Toma de Asistencia"
                this.sizeModal = "1200"

            },
            closeModal(){

                this.modalShow = false

            }

        },
        mounted(){

            this.obtener_datos()

        }             

    }
</script>