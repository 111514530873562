<template>
    <div>
        <v-container fluid>
            <v-row class="mb-4" align="center">
                <v-col md="4">
                    <v-text-field autocomplete="off" hide-details v-model="busqueda" label="Buscar..." prepend-inner-icon="mdi-account-search" clearable outlined></v-text-field>
                </v-col>
                <v-col align="end">
                    <v-btn @click="mostrar_modal()" color="primary">AGREGAR 
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="10"
                        class="elevation-2"
                        :search="busqueda"
                    >
                    
                        <template v-slot:[`item.action`]="data">

                            <v-btn :to="{ name: 'detalle_grado', params: { id: data.item.id } }" icon xs color="green darken-1">
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>

                            <v-btn @click="mostrar_editar(data.item.id)" icon xs color="blue darken-1">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn @click="eliminar_grado(data.item.id)" icon xs color="red">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>

                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

            <Modal @closeModal="modalShow = false" :modalShow="modalShow" :titleModal="titleModal" :sizeModal="sizeModal">
                <template #form-modal>
                    <Form :enableEdit="enableEdit" :idItem="idItem" @closeModal="modalShow = false" @updateTable="obtener_datos()"></Form>
                </template>
            </Modal>

        </v-container>
    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    import Modal from '@/components/Modal.vue'
    import Form from '@/components/grados/Form.vue'

    export default {
        components: {
            Modal,
            Form
        },
        data(){
            return{
                data_url: process.env.VUE_APP_API_URL + "obtener_grados",
                busqueda: null,
                headers: [],
                items: [],
                fab: false,
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                enableEdit: false,
                idItem: false
            }
        },
        methods: {

            obtener_datos(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {
                    sede_id: usuario.sede_id
                }

                this.axios.post(this.data_url, data)
                .then((response) => {
                    
                    this.headers = response.data.headers
                    this.items = response.data.items

                })

            },
            mostrar_modal(){

                this.titleModal = "Habilitar Grado"
                this.sizeModal = '700'
                this.enableEdit = false
                this.idItem = null
                this.modalShow = true

            },
            mostrar_editar(id){

                this.titleModal = "Editar Grado"
                this.sizeModal = '700'
                this.enableEdit = true
                this.idItem = id
                this.modalShow = true

            },
            eliminar_grado(id){

                let data = {

                    id_grado: id

                }

                Swal.fire({
                    title: '¿Está seguro?',
                    text: "Una vez eliminado no se podrá recuperar!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, ELIMINAR!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        this.axios.post(process.env.VUE_APP_API_URL + "eliminar_grado", data)
                        .then((response) => {

                            if (response.data) {
                                
                                Swal.fire({
                                    title: 'Excelente!',
                                    text: 'El grado a sido eliminado exitosamente',
                                    icon: 'success',
                                }).then(() => {

                                    this.obtener_datos()

                                })

                            }
                        
                        })

                    }
                })

            }

        },
        mounted(){

            this.obtener_datos()

        }     
    }
</script>