<template>
    <div>

        <v-container fluid>

            <v-row class="mb-4" align="center">
                <v-col md="4">
                    <v-text-field autocomplete="off" hide-details v-model="busqueda" label="Buscar..." prepend-inner-icon="mdi-account-search" clearable outlined></v-text-field>
                </v-col>
                <v-col align="end">
                    <v-btn @click="mostrarModal()" color="primary">AGREGAR 
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="10"
                        class="elevation-2"
                        :search="busqueda"
                    >
                        <template v-slot:[`item.nombre`]="data">
                            {{ data.item.nombre }} {{ data.item.segundo_nombre }} {{ data.item.apellido }} {{ data.item.segundo_apellido }}
                        </template>

                        <template v-slot:[`item.action`]="data">

                            <v-btn :to="{ name: 'detalle_alumno', params: { id: data.item.id } }" icon xs color="green darken-1">
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>

                            <v-btn @click="mostrarEditar(data.item.id)" icon xs color="primary darken-1">
                                <v-icon>mdi-account-edit</v-icon>
                            </v-btn>

                            <v-btn @click="eliminarAlumno(data.item.id)" icon xs color="red">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>

                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

            <Modal @closeModal="closeModal()" :modalShow="modalShow" :titleModal="titleModal" :sizeModal="sizeModal">
                <template #form-modal>
                    <Form :enableEdit="enableEdit" :idItem="idItem" @updateTable="obtener_datos()" @closeModal="closeModal()"></Form>
                </template>
            </Modal>

        </v-container>
        
    </div>
</template>

<script>

    import Modal from '@/components/Modal.vue'
    import Form from '@/components/Alumnos/Form.vue'

    import Swal from 'sweetalert2'

    export default {
        components: {
            Modal,
            Form  
        },
        data(){
            return{
                data_url: process.env.VUE_APP_API_URL + "obtener_alumnos_colegio",
                busqueda: null,
                headers: [],
                items: [],
                fab: false,
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                enableEdit: false,
                idItem: null
            }
        },
        methods: {

            obtener_datos(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {
                    id_usuario: usuario.id_usuario
                }

                this.axios.post(this.data_url, data)
                .then((response) => {
                    
                    this.headers = response.data.headers
                    this.items = response.data.items

                })

            },
            mostrarModal(){

                this.titleModal = "Agregar Alumno"
                this.sizeModal = "800"
                this.enableEdit = false
                this.idItem = null
                this.modalShow = true

            },
            closeModal(){

                this.modalShow = false

            },
            mostrarEditar(id){
                
                this.titleModal = "Editar Alumno"
                this.sizeModal = "800"
                this.enableEdit = true
                this.idItem = id
                this.modalShow = true

            },
            eliminarAlumno(id){

                let data = {

                    id_alumno: id

                }

                Swal.fire({
                    title: '¿Está seguro?',
                    text: "Una vez eliminado no se podrá recuperar!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, ELIMINAR!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        this.axios.post(process.env.VUE_APP_API_URL + "eliminar_alumno", data)
                        .then((response) => {

                            if (response.data) {
                                
                                Swal.fire({
                                    title: 'Excelente!',
                                    text: 'El alumno a sido eliminado exitosamente',
                                    icon: 'success',
                                }).then(() => {

                                    this.obtener_datos()

                                })

                            }
                        
                        })

                    }
                })

            }

        },
        mounted(){

            this.obtener_datos()

        }     
    }
</script>