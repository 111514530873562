<template>
    <div>

        <v-container fluid>
            <v-row align="center">
                <v-col>
                    <v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbs"></v-breadcrumbs>
                </v-col>
            </v-row>

                        <v-row dense class="mb-4" align="center">
                <v-col md="4">
                    <v-text-field hide-details v-model="busqueda" label="Buscar..." prepend-inner-icon="mdi-account-search" clearable outlined></v-text-field>
                </v-col>
                <v-col align="end">
                    <v-btn @click="mostrarModal()" color="primary">AGREGAR 
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="10"
                        class="elevation-2"
                        :search="busqueda"
                    >
                    
                        <template v-slot:[`item.action`]="data">

                            <v-btn :to="{ name: 'detalle_grado_pred', params: { id: data.item.id } }" icon xs color="success">
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>

                            <v-btn @click="modal_editar(data.item.id)" icon xs color="primary">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn @click="eliminar_sede(data.item.id)" icon xs color="red">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>

                        </template>

                    </v-data-table>
                </v-col>
            </v-row>
            
            <Modal @closeModal="modalShow = false" :titleModal="titleModal" :sizeModal="sizeModal" :modalShow="modalShow" ref="modal">
                <template #form-modal>
                    <Form :idItem="idItem" :editMode="editMode" @updateTable="obtener_datos" @closeModal="modalShow = false"></Form>
                </template>
            </Modal>

        </v-container>

    </div>
</template>

<script>

    import Modal from "@/components/Modal";
    import Form from "@/components/grados_pred/Form";

    import Swal from 'sweetalert2'

    export default {
        components: {
            Modal,
            Form
        },
        data(){
            return{
                busqueda: null,
                headers: [],
                items: [],
                fab: false,
                breadcrumbs: [
                    {
                        text: 'Administración',
                        href: '#/home/administracion',
                    },
                    {
                        text: 'Grados',
                        active: true
                    },
                ],
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                editMode: false,
                idItem: null
            }
        },   
        methods: {

            obtener_datos(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {

                    sede_id: usuario.sede_id

                }

                this.axios.post(process.env.VUE_APP_API_URL + "obtener_grados_pred_crud", data)
                .then((response) => {
                    
                    console.log(response.data);

                    this.headers = response.data.headers
                    this.items = response.data.items

                })

            },
            mostrarModal(){

                this.titleModal = "Agregar Grado"
                this.sizeModal = "800"
                this.editMode = false
                this.idItem = null
                this.modalShow = true                

            },
            modal_editar(id){

                this.titleModal = "Editar Grado"
                this.sizeModal = "800"
                this.editMode = true
                this.idItem = id
                this.modalShow = true

            },
            eliminar_sede(id){

                let data = {

                    id: id

                }

                Swal.fire({
                    title: '¿Está seguro?',
                    html: "<strong>Precaución: </strong>Si se elimina el grado no podrá acceder a los registros relacionados a este!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, ELIMINAR!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        this.axios.post(process.env.VUE_APP_API_URL + "eliminar_grado_pred", data)
                        .then((response) => {

                            if (response.data) {
                                
                                Swal.fire({
                                    title: 'Excelente!',
                                    text: 'La grado a sido eliminada exitosamente',
                                    icon: 'success',
                                }).then(() => {

                                    this.obtener_datos()

                                })

                            }
                        
                        })

                    }
                })

            }

        },
        mounted(){

            this.obtener_datos()

        }             
    }
</script>