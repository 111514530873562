<template>
	<div>
		<v-row class="mt-2">
			<v-col>
				<v-tabs v-model="tab">
					<v-tab>Mensualidades y Anualidades</v-tab>
					<v-tab>Otros Pagos</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-row class="mt-2">
							<v-col md="4">
								<v-text-field dense autocomplete="off" hide-details v-model="busqueda" label="Buscar..." prepend-inner-icon="mdi-account-search" clearable outlined></v-text-field>
							</v-col>
							<v-col align="end">
								<v-btn @click="generar_reporte('mensualidad')">
									<v-icon>
										mdi-printer
									</v-icon>
								</v-btn>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<v-data-table id="table_mensualidad" :search="busqueda" :headers="headers" :items="items_mensualidad"></v-data-table>
							</v-col>
						</v-row>
					</v-tab-item>
					<v-tab-item>
						
						<v-row class="mt-2">
							<v-col md="4">
								<v-text-field dense autocomplete="off" hide-details v-model="busqueda_otros" label="Buscar..." prepend-inner-icon="mdi-account-search" clearable outlined></v-text-field>
							</v-col>
							<v-col align="end">
								<v-btn @click="generar_reporte('otros')">
									<v-icon>
										mdi-printer
									</v-icon>
								</v-btn>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<v-data-table :search="busqueda_otros" :headers="headers" :items="items_otros"></v-data-table>
							</v-col>
						</v-row>

					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	export default {
		props: {
			date: String,
			day: Number,
			load: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return{
				tab: 0,
				busqueda: null,
				busqueda_otros: null,
				items_mensualidad: [],
				items_otros: [],
				headers: []
			}
		},
		methods: {

			obtener_datos(){

				const usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

				const data = {
					fecha: this.date,
					day: this.day,
					sede_id: usuario.sede_id
				}

				this.axios.post(process.env.VUE_APP_API_URL + "detalle_ingresos", data)
				.then((response) => {
					this.headers = response.data.headers
					this.items_mensualidad = response.data.items_mensualidad
					this.items_otros = response.data.items_otros
				})

			},
			generar_reporte(tipo){

				const usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

				const data = {
					fecha: this.date,
					day: this.day,
					sede_id: usuario.sede_id,
					tipo: tipo
				}

				this.axios.post(process.env.VUE_APP_API_URL + "reporte_ingresos", data)
				.then((response) => {

					console.log(response.data)

					const url = process.env.VUE_APP_API_URL  + response.data.pdf_url

					console.log(url)

					// const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'ingresos.pdf');
					link.setAttribute('target', '_blank');
					document.body.appendChild(link);
					link.click();

				})

			}

		},
		watch: {

			load: function(val){

				if (val) {
					this.obtener_datos()
				}

			}
		},
		mounted(){

			this.obtener_datos()

		}
	}
</script>

<style>

</style>