<template>

	<div>
		<v-row align="center" justify="center" class="mt-4">
			<v-col align="center">

				<iframe height="700" width="1100" :src="pdf"></iframe>

			</v-col>
		</v-row>
	</div>

</template>

<script>
	export default {
		data(){
			return{
				pdf: null
			}
		},
		methods: {

			generar_boleta(){

				

			}

		}
	}
</script>

<style>

</style>