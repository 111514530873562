<template>
	<div>
		<v-card>
			<v-card-text>
				<v-row align="center" dense>
					<v-col cols="2">
						<v-menu
							ref="menu"
							v-model="menu"
							:close-on-content-click="false"
							:return-value.sync="date"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="date"
									label="Inicio"
									prepend-inner-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
									hide-details
									outlined
								></v-text-field>
							</template>
							<v-date-picker
							v-model="date"
							no-title
							scrollable
							>
							<v-spacer></v-spacer>
							<v-btn
								text
								color="primary"
								@click="menu = false"
							>
								Cancelar
							</v-btn>
							<v-btn
								text
								color="primary"
								@click="change_date(date)"
							>
								OK
							</v-btn>
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="2">
						<v-menu
							ref="menu2"
							v-model="menu2"
							:close-on-content-click="false"
							:return-value.sync="date2"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="date2"
									label="Fin"
									prepend-inner-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
									hide-details
									outlined
								></v-text-field>
							</template>
							<v-date-picker
							v-model="date2"
							no-title
							scrollable
							>
							<v-spacer></v-spacer>
							<v-btn
								text
								color="primary"
								@click="menu = false"
							>
								Cancelar
							</v-btn>
							<v-btn
								text
								color="primary"
								@click="change_date2(date2)"
							>
								OK
							</v-btn>
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col align="end">
						
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-text>
				<v-row>
					<v-col cols="12">
						<highcharts :options="chartOptions"></highcharts>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<Modal @closeModal="closeModal" :modalShow="modalShow" :titleModal="titleModal" :sizeModal="sizeModal">
			<template #form-modal>
				<DetalleReporte :load="load" :date="date" :day="day"></DetalleReporte>
			</template>
		</Modal>
	</div>
</template>

<script>

	import Modal from '@/components/Modal'
	import DetalleReporte from '@/components/reportes/DetalleReporte'

	export default {
		components: {
			Modal,
			DetalleReporte
		},	
		data() {
			return {
				date: new Date().toISOString().substr(0, 10),
				date2: new Date().toISOString().substr(0, 10),
				menu: false,
				menu2: false,
				modalShow: false,
				titleModal: null,
				sizeModal: null,
				chartOptions: {
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: 'pie'
					},
					title: {
						text: 'Total de Ingresos por Jornada'
					},
					subtitle: {
						text: 'Fecha: '
					},
					tooltip: {
						pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
					},
					accessibility: {
						point: {
							valueSuffix: '%'
						}
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: 'pointer',
							dataLabels: {
								enabled: true,
								format: '<b>{point.name}</b>: Q{point.y:.1f}'
							}
						}
					},
					series: [{
						name: 'Jornadas',
						colorByPoint: true,
						data: []
					}]
				},
				day: null,
				load: false
			}
		},
		methods: {

			obtener_datos(){

				const usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

				const data = {
					fecha: [this.date, this.date2],
					sede_id: usuario.sede_id
				}

				console.log(data)

				this.axios.post(process.env.VUE_APP_API_URL + "grafica_jornada", data)
				.then((response) => {
					
					console.log(response.data);
					this.chartOptions.series[0].data = response.data.chart_data
					this.chartOptions.title.text = response.data.title
					this.chartOptions.subtitle.text = response.data.subtitle

				})

			},
			change_date(date){

				this.$refs.menu.save(date)
				this.obtener_datos()

			},
			change_date2(date){

				this.$refs.menu2.save(date)
				this.obtener_datos()

			},
			mostrar_detalle(){

				this.modalShow = true
				this.titleModal = 'Detalle de Ingresos'
				this.sizeModal = '1200'
				this.day = null
				this.load = true

			},
			mostrar_detalle_dia(day){

				this.modalShow = true
				this.titleModal = 'Detalle de Ingresos Diario'
				this.sizeModal = '1200'
				this.day = day
				this.load = true

			},
			closeModal(){

				this.modalShow = false
				this.load = false

			}

		},
		watch: {

			

		},
		mounted(){

			this.obtener_datos()

		}
	}
</script>

<style>

</style>