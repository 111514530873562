<template>
	<div>
		<v-form ref="form" v-model="valid" @submit.prevent="actualizar()">
			<v-row class="mt-4">
				<v-col>
					<v-text-field :rules="[v => !!v]" autocomplete="off" v-model="ciclo" label="Ciclo" outlined hide-details></v-text-field>
				</v-col>
			</v-row>
			<v-row class="mt-4" align="center" justify="center">
				<v-col align="center" cols="12">
					<v-btn
						color="secondary"
						@click="() => { this.$emit('closeModal') }"
					>
						Cancelar
					</v-btn>

					<v-btn
						color="primary"
						class="ml-2"
						type="submit"
					>
						Aceptar
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</div>
</template>

<script>
    export default {
		data(){
			return{
				ciclo: null,
				valid: true
			}
		},
		methods: {

			actualizar(){

				this.$refs.form.validate()

				if (this.valid) {
					
					this.$emit('actualizar_ciclo', this.ciclo)
					this.$emit('closeModal')

				}

			}

		}
    }
</script>

<style>

</style>