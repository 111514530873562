<template>
    <div>

        <v-container class="mt-4" fluid>
             <v-row class="mb-4" align="center">
                <v-col md="4">
                    <v-text-field hide-details v-model="busqueda" label="Buscar..." prepend-inner-icon="mdi-account-search" clearable outlined></v-text-field>
                </v-col>
                <v-col align="end">
                    <v-btn @click="mostrar_modal()" color="primary" class="mr-2">AGREGAR 
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="10"
                        class="elevation-2"
                        :search="busqueda"
                    >

                        <template v-slot:[`item.monto`]="data">

                            <strong>
                                Q {{ data.item.monto }}
                            </strong>

                        </template>

                        <template v-slot:[`item.tipo_pago`]="data">

                            <v-btn x-small :color="data.item.color">{{ data.item.tipo_pago }}</v-btn>

                        </template>

                        <template v-slot:[`item.action`]="data">

                            <v-btn @click="modal_editar(data.item.id)" icon xs color="primary">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn @click="eliminar(data.item.id)" icon xs color="red">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>

                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

            <Modal @closeModal="modalShow = false" :titleModal="titleModal" :sizeModal="sizeModal" :modalShow="modalShow">
                <template #form-modal>
                    <Form :idItem="idItem" :editMode="editMode" @closeModal="modalShow = false" @updateTable="obtener_datos"></Form>
                </template>
            </Modal>

        </v-container>

    </div>
</template>

<script>

    import Modal from '@/components/Modal.vue'
    import Form from '@/components/grados_pred/FormPago.vue'

    import Swal from 'sweetalert2'
    
    export default {
        components: {
            Modal,
            Form
        }, 
        data(){
            return{
                data_url: process.env.VUE_APP_API_URL + "pagos_grado_pred",
                busqueda: null,
                headers: [],
                items: [],
                fab: false,
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                editMode: false,
                idItem: null
            }
        },
        methods: {

            obtener_datos(){

                let data = {
                    id: this.$route.params.id
                }

                this.axios.post(this.data_url, data)
                .then((response) => {
                    
                    this.headers = response.data.headers
                    this.items = response.data.items

                })

            },
            mostrar_modal(){

                this.titleModal = "Agregar Pago"
                this.sizeModal = '800'
                this.editMode = false
                this.idItem = null
                this.modalShow = true

            },
            modal_editar(id){

                this.titleModal = "Editar Pago"
                this.sizeModal = "800"
                this.editMode = true
                this.idItem = id
                this.modalShow = true

            },
            eliminar(id){

                let data = {

                    id: id

                }

                Swal.fire({
                    title: '¿Está seguro?',
                    html: "<strong>Precaución: </strong>Si se elimina el pago no podrá acceder a los registros relacionados a este!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, ELIMINAR!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        this.axios.post(process.env.VUE_APP_API_URL + "eliminar_pago_grado_pred", data)
                        .then((response) => {

                            if (response.data) {
                                
                                Swal.fire({
                                    title: 'Excelente!',
                                    text: 'El pago a sido eliminada exitosamente',
                                    icon: 'success',
                                }).then(() => {

                                    this.obtener_datos()

                                })

                            }
                        
                        })

                    }
                })

            }

        },
        mounted(){

            this.obtener_datos()

        }             

    }
</script>