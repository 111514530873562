<template>
    <div>

        <v-form ref="form" v-model="valid" @submit.prevent="!enableEdit ? registrar() : editar()">
            <v-row class="mt-4">
                <v-col cols="6">
                    <v-text-field v-model="alumno.nombre" :rules="[v => !!v || 'Campo obligatorio!']" hide-details autocomplete="off" label="Primer Nombre" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="alumno.segundo_nombre" hide-details autocomplete="off" label="Segundo Nombre" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="alumno.apellido" :rules="[v => !!v || 'Campo obligatorio!']" hide-details autocomplete="off" label="Primer Apellido" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="alumno.segundo_apellido" hide-details autocomplete="off" label="Segundo Apellido" outlined></v-text-field>
                </v-col>

                <v-col cols="3">
                    <v-text-field v-model="alumno.edad" hide-details autocomplete="off" label="Edad" type="number" outlined></v-text-field>
                </v-col>

                <v-col cols="3">
                    <v-text-field v-model="alumno.telefono" hide-details autocomplete="off" label="Teléfono" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="alumno.direccion" hide-details autocomplete="off" label="Dirección" outlined></v-text-field>
                </v-col>

            </v-row>

            <v-row class="mt-4" align="center" justify="center">
                <v-col align="center" cols="12">
                    <v-btn
						color="secondary"
						@click="() => { this.$emit('closeModal') }"
					>
						Cancelar
					</v-btn>

					<v-btn
						color="primary"
                        class="ml-2"
						type="submit"
					>
						Aceptar
					</v-btn>
                </v-col>
            </v-row>
        </v-form>

    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        
        props: {
            enableEdit: Boolean,
            idItem: Number
        },
        data(){
            return{
                alumno: {
                    nombre: null,
                    segundo_nombre:  null,
                    apellido: null,
                    segundo_apellido: null,
                    edad: null,
                    telefono: null,
                    direccion: null
                },
                valid: true
            }
        },
        methods: {

            registrar(){

                this.$refs.form.validate()

                if (this.valid) {
                    
                    let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                    this.alumno.sede_id = usuario.sede_id

                    this.axios.post(process.env.VUE_APP_API_URL + "registrar_alumno", this.alumno)
                    .then((response) => {
                        
                        if (response.data) {
                            
                            Swal.fire({
                                title: 'Excelente!',
                                text: 'El alumno a sido registrado exitosamente',
                                icon: 'success',
                            }).then(() => {

                                this.$emit('closeModal')
                                this.$emit('updateTable') 

                            })

                        }

                    })

                }

            },
            obtener_detalle(id){

                let data = {

                    id_alumno: id

                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_alumno", data)
                .then((response) => {
                    
                    this.alumno = response.data

                })

            },
            editar(){

                this.axios.post(process.env.VUE_APP_API_URL + "editar_alumno", this.alumno)
                .then((response) => {
                    
                    if (response.data) {
                            
                            Swal.fire({
                                title: 'Excelente!',
                                text: 'El alumno a sido editado exitosamente',
                                icon: 'success',
                            }).then(() => {

                                this.$emit('closeModal')
                                this.$emit('updateTable') 

                            })

                        }

                })

            }

        },
        watch: {

            idItem: function(val){
                
                this.obtener_detalle(val)

            }
        },
        mounted(){
            
            this.valid = true

            if (this.enableEdit) {
                
                this.obtener_detalle(this.idItem)

            }

        }

    }
</script>

