<template>
    <div>

        <v-container fluid>
            
            <v-row dense align="center">
                <v-col cols="10" md="11" lg="10">
                    <v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbs"></v-breadcrumbs>
                </v-col>
                <v-col align="end" justify="end" cols="2" md="2" lg="2">
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="alumno.nombre" readonly hide-details autocomplete="off" label="Primer Nombre" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="alumno.segundo_nombre" readonly hide-details autocomplete="off" label="Segundo Nombre" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="alumno.apellido" readonly hide-details autocomplete="off" label="Primer Apellido" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="alumno.segundo_apellido" readonly hide-details autocomplete="off" label="Segundo Apellido" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="alumno.edad" readonly hide-details autocomplete="off" label="Edad" type="number" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="alumno.telefono" readonly hide-details autocomplete="off" label="Teléfono" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field v-model="alumno.direccion" readonly hide-details autocomplete="off" label="Dirección" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="alumno.grado" readonly hide-details autocomplete="off" label="Grado" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="alumno.nivel" readonly hide-details autocomplete="off" label="Nivel" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="alumno.seccion" readonly hide-details autocomplete="off" label="Sección" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="alumno.ciclo" readonly hide-details autocomplete="off" label="Ciclo Escolar" outlined></v-text-field>
                                </v-col>

                            </v-row>

                            
                        </v-card-text>
                        <v-card-text>
                            <v-row align="center">
                                <v-col>
                                    <small>Fecha de registro: {{ alumno.created_at }}</small>
                                </v-col>
                                <v-col align="end">
                                    <v-chip label color="cyan" dark class="mr-2">
                                        <v-avatar left>
                                            <v-icon>mdi-account-circle</v-icon>
                                        </v-avatar>

                                        <strong>
                                            Usuario: 
                                            {{ alumno.usuario }}
                                        </strong>
                                    </v-chip>
                                    <v-btn @click="modalInscribir()" color="primary" class="mr-2">INSCRIBIR 
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                    <v-btn @click="modalBecar()" color="success">BECAR 
                                        <v-icon>mdi-sale</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col>

                    <v-card>
                        <v-tabs v-model="tab">
                            <v-tab>Historial de Pagos</v-tab>
                            <v-tab>Calificaciones</v-tab>
                            <v-tab>Encargados</v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <HistorialPagos :alumno="alumno"></HistorialPagos>
                            </v-tab-item>

                            <v-tab-item>
                                <Calificaciones></Calificaciones>
                            </v-tab-item>

                            <v-tab-item>
                                <Encargados></Encargados>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>

                </v-col>
            </v-row>

            <Modal @closeModal="modalShow = false" :modalShow="modalShow" :titleModal="titleModal" :sizeModal="sizeModal">
                <template #form-modal>
                    <FormInscripcion v-if="form == 'form-inscribir'" @actualizarDetalle="obtener_datos" @closeModal="modalShow = false"></FormInscripcion>
                    <FormBecar @actualizarDetalle="obtener_datos" @closeModal="modalShow = false" ref="form_becar" v-if="form == 'form-becar'"></FormBecar>
                </template>
            </Modal>

        </v-container>

    </div>
</template>

<script>

    import HistorialPagos from '@/components/Alumnos/HistorialPagos.vue'
    import Calificaciones from '@/components/Alumnos/Calificaciones.vue'
    import Encargados from '@/components/Alumnos/Encargados.vue'

    import Modal from '@/components/Modal.vue'

    import FormInscripcion from '@/components/Alumnos/FormInscripcion.vue'
    import FormBecar from '@/components/Alumnos/FormBecar.vue'

    export default {
        components: {

            HistorialPagos,
            Calificaciones,
            Encargados,
            Modal,
            FormInscripcion,
            FormBecar

        },
        data(){

            return{
                breadcrumbs: [
                    {
                        text: 'Alumnos',
                        href: '#/home/alumnos',
                    },
                    {
                        text: 'Detalle del Alumno',
                        active: true
                    },
                ],
                alumno: {},
                tab: 0,
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                form: null
            }

        },
        methods: {

            obtener_datos(){

                let data = {
                    id_alumno: this.$route.params.id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_alumno", data)
                .then((response) => {
                    
                    this.alumno = response.data
                    
                })

            },
            modalInscribir(){

                this.titleModal = 'Inscribir Alumno'
                this.sizeModal = '700'
                this.form = 'form-inscribir'
                this.modalShow = true

            },
            modalBecar(){

                this.titleModal = 'Becar'
                this.sizeModal = '500'
                this.form = 'form-becar'

                this.modalShow = true

                if (this.$refs.form_becar) {
                    this.$refs.form_becar.obtener_descuento()
                }
                
            }

        },
        mounted(){

            this.obtener_datos()

        }
    }
</script>