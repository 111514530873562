<template>
    <div>

        <v-container fluid>
            
            <v-row dense align="center">
                <v-col>
                    <v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbs"></v-breadcrumbs>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="grado.nombre" readonly hide-details autocomplete="off" label="Grado" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="grado.nivel" readonly hide-details autocomplete="off" label="Nivel" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="grado.seccion" readonly hide-details autocomplete="off" label="Sección" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="grado.ciclo_escolar" readonly hide-details autocomplete="off" label="Ciclo Escolar" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field v-model="grado.maestro" readonly hide-details autocomplete="off" label="Maestro Guía" outlined></v-text-field>
                                </v-col>

                                

                            </v-row>

                            
                        </v-card-text>
                        <v-card-text>
                            <v-row align="center">
                                <v-col>
                                    <small>Fecha de registro: {{ grado.created_at }}</small>
                                </v-col>
                                <v-col align="end">
                                   
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col>

                    <v-card>
                        <v-tabs v-model="tab">
                            <v-tab>Alumnos</v-tab>
                            <v-tab>Cursos</v-tab>
                        </v-tabs>
                        
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <Alumnos></Alumnos>
                            </v-tab-item>

                            <v-tab-item>
                                <Cursos></Cursos>
                            </v-tab-item>

                           
                        </v-tabs-items>

                    </v-card>

                </v-col>
            </v-row>

            <Modal :modalShow="modalShow"></Modal>
        </v-container>
    </div>
</template>

<script>

    import Alumnos  from "@/components/grados/Alumnos.vue";
    import Cursos from '@/components/grados/Cursos'

    import Modal from '@/components/Modal.vue'

    export default {

        components: {
            Alumnos,
            Cursos,
            Modal
        },
        data(){

            return{
                breadcrumbs: [
                    {
                        text: 'Grados',
                        href: '#/home/grados',
                    },
                    {
                        text: 'Detalle de Grado',
                        active: true
                    },
                ],
                grado: {},
                tab: 0,
                modalShow: false
            }

        },
         methods: {

            obtener_datos(){

                let data = {
                    id_grado: this.$route.params.id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_grado", data)
                .then((response) => {
                    
                    this.grado = response.data
                    
                })

            }

        },
        mounted(){

            this.obtener_datos()

        }

    }
</script>