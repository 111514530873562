<template>
    <div>
        <section id="about-me">
            <div class="py-12"></div>

            <v-container class="text-center">
                <h2 class="display-2 font-weight-bold mb-3">REQUISITOS DE ADMINISIÓN</h2>

                <v-responsive
                    class="mx-auto mb-8"
                    width="56"
                >
                    <v-divider class="mb-1"></v-divider>

                    <v-divider></v-divider>
                </v-responsive>

                <v-responsive
                    class="mx-auto title font-weight-light mb-8"
                    max-width="720"
                >
                    
                    <v-row>

                        <v-col cols="12">
                            <h4>Preprimaria</h4>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Fe de edad original reciente del RENAP con dos fotocopias
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Constancia de código personal
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>

                        <v-col cols="12">
                            <h4>Primaria</h4>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Fe de edad original reciente del RENAP con dos fotocopias
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Certificado de grados anteriores
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Constancia de buena conducta
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Constancia de código personal
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>

                        <v-col cols="12">
                            <h4>Ciclo Básico</h4>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Fe de edad original reciente del RENAP con dos fotocopias
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Certificado de grados anteriores
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Constancia de buena conducta
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Constancia de código personal
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Diploma de 6to. primaria
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>

                        <v-col cols="12">
                            <h4>Diversificado</h4>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Fe de edad original reciente del RENAP con dos fotocopias
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Certificado de 6to. Primaria con su diploma
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Certificado de 1ro, 2do y 3ro Básico
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Diploma del Ciclo Básico
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Certificado de mecanografia
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Constancia de buena conducta
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        Constancia de código personal
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-responsive>
            </v-container>
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>