<template>
	<v-app>
		
			<router-view></router-view>

			<Notificacion></Notificacion>
	</v-app>
</template>

<script>

	import { firebase } from '@firebase/app'
	import "firebase/messaging"

	import Notificacion from '@/components/Notification'

	export default {
		name: 'App',

		components: {
			Notificacion
		},

		data: () => ({
			//
		}),
		created(){

			try {
				firebase
				.messaging()
				.requestPermission()
				.then(() => {

					return firebase.messaging().getToken()
					.then((token) => {

						if (token) {
							
							const local = JSON.parse(localStorage.getItem('plataforma-sinai'))

							// Si existe ya un localStorage actualizar
							if (local) {
								
								local.token = token

								localStorage.setItem('plataforma-sinai', JSON.stringify(local))

							}else {

								const data = {
									token: token
								}

								localStorage.setItem('plataforma-sinai', JSON.stringify(data))

							}

						}else{

							const data = {
								token: null
							}

							localStorage.setItem('plataforma-sinai', JSON.stringify(data))

						}

					})
					.catch((err) => {

						console.log('Unable to get token ', err);

					})
				})
			} catch (error) {
				console.log(error);
			}

		}
	};
</script>
