<template>
    <div>

        <v-card
            class="mx-auto mt-4"
            tile
        >

            <v-card-text>
                <v-btn @click="permitir_acceso(alumno.permitir_acceso ? null : 'S')" :color="alumno.permitir_acceso ? 'error' : 'primary'">
                    {{ alumno.permitir_acceso ? 'REMOVER ACCESO' : 'PERMITIR ACCESO' }}
                </v-btn>

                <v-divider class="mt-4 pb-0 mb-0"></v-divider>
            </v-card-text>
            <v-list-item v-for="(item, index) in pagos" :key="index" two-line>
                <v-list-item-content>
                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                    <v-list-item-subtitle>Monto: Q{{ item.monto }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

          
        </v-card>
    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        props: {
            pagos: {
                type: Array,
                default: null
            },
            alumno: Object
        },
        methods: {

            permitir_acceso(val){

                let data = {

                    id_alumno: this.alumno.id,
                    permitir_acceso: val

                }   

                this.axios.post(process.env.VUE_APP_API_URL + "permitir_acceso", data)
                .then((response) => {
                    
                    if (response.data.status == 200) {
                        
                        Swal.fire({
                            title: 'Excelente!',
                            text: 'El estado del alumno a sido actualizado',
                            icon: 'success',
                        }).then(() => {

                            location.reload()

                        })

                    }
                    
                })

            }

        }        
    }
</script>