<template>
    <div>
        <v-form class="mt-4">

            <!-- Buscar el grado que se desea habilitar -->
            <v-row>
                <v-col v-if="enableEdit" cols="12">
                    <v-alert class="mb-0" color="success" text>
                        <v-row dense>
                            <v-col cols="6">
                                <small><strong>Grado:</strong> {{ gradoEdit.nombre }}</small>
                            </v-col>
                            <v-col cols="6">
                                <small><strong>Nivel: </strong>{{ gradoEdit.nivel }}</small>
                            </v-col>
                            <v-col cols="6">
                                <small><strong>Jornada:</strong> {{ gradoEdit.jornada }}</small>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
                <v-col>
                    <v-card>
                        <v-card-title>Seleccione un grado</v-card-title>
                        <v-card-text>

                            <v-list>
                                <v-list-group dense v-for="(nivel) in niveles" :key="nivel.id" :prepend-icon="nivel.icono">
                                    <template v-slot:activator>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="nivel.nombre"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>

                                    <v-list-item
                                        v-for="grado in nivel.grados"
                                        :key="grado.id"
                                    >   
                                        <v-list-item-icon>
                                            <v-btn :color="grado.select ? 'success' : 'secondary'" @click="seleccionar_grado(grado)" text>
                                                <v-icon>
                                                    {{ grado.select ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline' }}
                                                </v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="grado.nombre"></v-list-item-title>
                                            <v-list-item-subtitle style="text-transform: capitalize">Jornada: {{ grado.jornada }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                </v-list-group>
                            </v-list>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center" class="mt-4">
                <v-col cols="6">
                    <v-text-field autocomplete="off" v-model="nuevo_grado.ciclo_escolar" :disabled="!gradoSelect" label="Ciclo Escolar" hide-details outlined></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field autocomplete="off" v-model="nuevo_grado.seccion" :disabled="!gradoSelect" label="Sección" hide-details outlined></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col md="12">
                    <v-select v-model="nuevo_grado.maestro_id" :items="maestros" item-text="nombre" item-value="id" :disabled="!gradoSelect" label="Maestro Guía" hide-details outlined></v-select>
                </v-col>
            </v-row>

            <v-row class="mt-4" align="center" justify="center">
            <v-col align="center" cols="12">
                <v-btn
                    color="secondary"
                    @click="() => { this.$emit('closeModal') }"
                >
                    Cancelar
                </v-btn>

                <v-btn
                    color="primary"
                    class="ml-2"
                    :disabled="!gradoSelect"
                    @click="!enableEdit ? registrar_grado() : editar_grado()"
                >
                    Aceptar
                </v-btn>
            </v-col>
        </v-row>

        </v-form>
    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        
        props: {
            enableEdit: Boolean,
            idItem: Number
        },
        data(){
            return{
                niveles: [],
                gradoSelect: null,
                maestros: [],
                nuevo_grado: {
                    seccion: null,
                    ciclo_escolar: null,
                    jornada_id: null,
                    maestro_id: null,
                    grado_pred_id: null
                },
                gradoEdit: {}
            }
        },
        methods: {

            obtener_grados(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {
                    sede_id: usuario.sede_id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "obtener_grados_pred", data)
                .then((response) => {
                    
                    this.niveles = response.data
                    
                })

            },
            seleccionar_grado(gradoSelect){

                this.niveles.forEach(nivel => {
                    nivel.grados.forEach(grado => {
                        
                        if (grado.id != gradoSelect.id) {
                            grado.select = false
                        }
                    });
                });

                gradoSelect.select = !gradoSelect.select

                if (gradoSelect.select) {
                    
                    this.gradoSelect = gradoSelect.id
                    this.nuevo_grado.grado_pred_id = gradoSelect.id
                    this.nuevo_grado.jornada_id = gradoSelect.jornada_id

                }else{

                    this.gradoSelect = null

                }

            },
            obtener_maestros(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {
                    sede_id: usuario.sede_id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "obtener_maestros", data)
                .then((response) => {
                    
                    this.maestros = response.data
                    
                })

            },
            registrar_grado(){

                this.axios.post(process.env.VUE_APP_API_URL + "registrar_grado", this.nuevo_grado)
                .then((response) => {
                                        
                    if (response.data) {
                        
                        Swal.fire({
                            title: 'Excelente!',
                            text: 'El grado a sido habilitado exitosamente',
                            icon: 'success',
                        }).then(() => {

                            this.$emit('updateTable')
                            this.$emit('closeModal')

                        })

                    }

                })

            },
            obtener_detalle(id){

                this.gradoSelect = null

                let data = {
                    id: id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_grado_editar", data)
                .then((response) => {
                    
                    this.nuevo_grado = response.data

                    this.gradoSelect = response.data.grado_pred_id

                    this.niveles.forEach(nivel => {
                        
                        nivel.grados.forEach(grado => {
                            
                            if (grado.id == this.gradoSelect) {
                                
                                this.gradoEdit = grado
                                console.log(grado);
                                grado.select = true

                            }else{

                                grado.select = false

                            }

                        });
                    });

                })

            },
            editar_grado(){

                this.axios.post(process.env.VUE_APP_API_URL + "editar_grado", this.nuevo_grado)
                .then((response) => {
                                        
                    if (response.data) {
                        
                        Swal.fire({
                            title: 'Excelente!',
                            text: 'El grado a sido editado exitosamente',
                            icon: 'success',
                        }).then(() => {

                            this.$emit('updateTable')
                            this.$emit('closeModal')

                        })

                    }

                })

            }

        },
        watch: {

            idItem: function(val){
                
                this.obtener_detalle(val)

            }
        },
        mounted(){

            this.obtener_grados()
            this.obtener_maestros()

            if (this.enableEdit) {
                
                this.obtener_detalle(this.idItem)

            }

        }

    }
</script>