<template>
    <div>
        <v-card :to="{ name: url }" color="grey">
            <v-card-text class="text-center">
                <h1 class="white--text">{{ text }}</h1>
            </v-card-text>
            <v-card-text>
                <v-row align="center" justify="center">
                    <v-col cols="6" md="4">
                        <v-img width="125" :src="require('@/assets/img/' + icon ) "></v-img>
                    </v-col>
                </v-row>
                
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        props: {
            url: String,
            text: String,
            icon: String
        }    
    }
</script>