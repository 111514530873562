<template>
    <div>
        <v-container fluid>
			<v-row align="center">
                <v-col md="4">
                    <v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbs"></v-breadcrumbs>
                </v-col>
				<v-col align="end" md="8">
					<v-btn @click="mostrar_boleta()" color="success">BOLETA 
                        <v-icon>mdi-text-box-check</v-icon>
                    </v-btn>
				</v-col>
            </v-row>
            <v-row>
                <v-col v-for="curso in items" :key="curso.id" md="3" lg="3" cols="12">
                    <v-card rounded min-height="170" >

                        <v-container>
                            <v-row align="start" dense>
                                <v-col>
                                    <v-card-title>{{ curso.nombre }}</v-card-title>
                                    <v-card-subtitle>Catedratico(a): {{ curso.maestro  ? curso.maestro : 'Pendiente'}} </v-card-subtitle>
                                </v-col>
                            </v-row>

                            <v-row dense>
								<v-col>
									<!-- <v-card outlined class="mb-4" v-for="(unidad, key) in curso.unidades" :key="key">
										<v-card-title>
											Unidad {{ unidad.id }}
											<v-spacer></v-spacer>
											Calificación: {{ unidad.calificacion }}
										</v-card-title>
									</v-card> -->
									<v-list>
										<v-list-group
											v-for="(unidad, key) in curso.unidades"
											:key="key"
											v-model="unidad.active"
										>
											<template v-slot:activator>
												<v-list-item-content>
													<v-list-item-title>Unidad {{ unidad.id }}</v-list-item-title>
													<v-list-item-subtitle>
														<small>
															Calificación: {{ unidad.calificacion }}
														</small>
													</v-list-item-subtitle>
												</v-list-item-content>
											</template>

											<v-list-item
												v-for="(tarea, index) in unidad.tareas"
												:key="index"
											>
												<v-list-item-content>
													<v-list-item-title v-text="tarea.nombre"></v-list-item-title>
													<v-list-item-subtitle>Puntos: 
														{{ tarea.calificacion ? tarea.calificacion : null }}

														<v-chip v-if="!tarea.calificacion" dark color="red" x-small label>
															PENDIENTE
														</v-chip>

													</v-list-item-subtitle>
													<v-divider class="mt-2"></v-divider>
												</v-list-item-content>
												
											</v-list-item>
												
										</v-list-group>
										</v-list>
								</v-col>
							</v-row>
                        </v-container>
                        
                    </v-card>
                </v-col>
            </v-row>
			
			<Modal @closeModal="closeModal()" :modalShow="modalShow" :titleModal="titleModal" :sizeModal="sizeModal">
                <template #form-modal>
                    <FormPreview :id="id" :url="url"></FormPreview>
                </template>
            </Modal>

        </v-container>
    </div>
</template>

<script>

	import Modal from '@/components/Modal'
    import FormPreview from '@/components/FormPreview'

    export default {
		components: {
            Modal,
            FormPreview
        },
        data(){
            return{
                cursos: [],
				breadcrumbs: [
                    {
                        text: 'Alumnos',
                        href: '#/home/alumnos_encargado',
                    },
                    {
                        text: 'Cursos',
                        active: true
                    },
                ],
				headers: [],
                items: [],
				expanded: [],
                single_expand: false,
				busqueda: null,
				api_url: process.env.VUE_APP_API_URL + 'descargar_archivo/',
				modalShow: false,
                titleModal: null,
                sizeModal: null,
                id: null,
                url: null
            }
        },
        methods: {
            obtener_cursos(){

                let data = {

                    id_alumno: this.$route.params.id

                }

                this.axios.post(process.env.VUE_APP_API_URL + "obtener_cursos_alumno", data)
                .then((response) => {
                    
                    console.log(response.data);

                    this.cursos = response.data.items

                })

            },
			obtener_datos(){

                let data = {
                    id_alumno: this.$route.params.id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "obtener_cursos_alumno", data)
                .then((response) => {
                    
                    console.log(response.data);
                    this.headers = response.data.headers
                    this.items = response.data.items

                })

            },
            descargar(item){

                console.log(item);

            },
            mostrar_boleta(){

                this.titleModal = "Pagos"
                this.sizeModal = "1200"
                this.id = parseInt(this.$route.params.id)
                this.url = 'boleta_calificaciones'
                this.modalShow = true


            },
            closeModal(){

                this.modalShow = false

            }
			
            
        },
        mounted(){

            this.obtener_cursos()
			this.obtener_datos()

        }    
    }
</script>