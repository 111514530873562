<template>
    <div>
        <v-form ref="form" @submit.prevent="!editMode ? registrar() : editar()" v-model="valid">
            <v-row class="mt-4">
                <v-col cols="6">
                    <v-text-field v-model="empleado.nombre" :rules="[v => !!v || 'Campo obligatorio!']" autocomplete="off" hide-details label="Primer Nombre" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="empleado.segundo_nombre" autocomplete="off" hide-details label="Segundo Nombre" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="empleado.apellido" :rules="[v => !!v || 'Campo obligatorio!']" autocomplete="off" hide-details label="Primer Apellido" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="empleado.segundo_apellido" autocomplete="off" hide-details label="Segundo Apellido" outlined></v-text-field>
                </v-col>

                <v-col cols="2">
                    <v-text-field v-model="empleado.edad" autocomplete="off" hide-details label="Edad" outlined></v-text-field>
                </v-col>

                <v-col cols="4">
                    <v-text-field v-model="empleado.telefono" autocomplete="off" hide-details label="Teléfono" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="empleado.direccion" autocomplete="off" hide-details label="Dirección" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="empleado.email" autocomplete="off" hide-details label="Email" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="empleado.area_profesional" autocomplete="off" hide-details label="Área Profesional" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-select v-model="empleado.tipo_empleado_id" :items="tipos_empleados" item-text="nombre" item-value="id" autocomplete="off" hide-details label="Tipo de Empleado" outlined></v-select>
                </v-col>

            </v-row>

            <v-row class="mt-4" align="center" justify="center">
                <v-col align="center" cols="12">
                    <v-btn
                        color="secondary"
                        @click="() => { this.$emit('closeModal') }"
                    >
                        Cancelar
                    </v-btn>

                    <v-btn
                        color="primary"
                        class="ml-2"
                        type="submit"
                    >
                        Aceptar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        props: {
            idItem: Number,
            editMode: Boolean
        },
        data(){
            return{
                tipos_empleados: [],
                valid: true,
                empleado: {
                    nombre: null,
                    segundo_nombre: null,
                    apellido: null,
                    segundo_apellido: null,
                    edad: null,
                    telefono: null,
                    direccon: null,
                    email: null,
                    area_profesional: null,
                    tipo_empleado_id: null,
                    sede_id: null
                }
            }
        },
        methods: {

            obtener_tipos_empleados(){

                this.axios.post(process.env.VUE_APP_API_URL + "obtener_tipos_empleados")
                .then((response) => {
                    
                    this.tipos_empleados = response.data

                })

            },
            registrar(){
                
                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                this.empleado.sede_id = usuario.sede_id

                this.axios.post(process.env.VUE_APP_API_URL + "registrar_empleado", this.empleado)
                .then((response) => {
                    
                    if (response.data) {
                            
                        Swal.fire({
                            title: 'Excelente!',
                            text: 'El colaborador a sido registrado exitosamente',
                            icon: 'success',
                        }).then(() => {

                            this.$emit('closeModal')
                            this.$emit('updateTable') 

                        })

                    }

                })

            },
            obtener_detalle(){

                let data = {

                    id: this.idItem

                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_colaborador", data)
                .then((response) => {
                    
                    this.empleado = response.data

                })

            },
            editar(){

                this.axios.post(process.env.VUE_APP_API_URL + "editar_empleado", this.empleado)
                .then((response) => {
                    
                    if (response.data) {
                            
                        Swal.fire({
                            title: 'Excelente!',
                            text: 'El colaborador a sido editado exitosamente',
                            icon: 'success',
                        }).then(() => {

                            this.$emit('closeModal')
                            this.$emit('updateTable') 

                        })

                    }

                })

            }
        },
        watch: {

            idItem: function(val){

                if (val) {
                    
                    this.obtener_detalle()

                }

            }

        },
        mounted(){

            if (this.editMode) {
                
                this.obtener_detalle()

            }

            this.obtener_tipos_empleados()

        }

    }
</script>