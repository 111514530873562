<template>
    <div class="mt-4">
        <v-form @submit.prevent="registrar()" v-model="valid" ref="form">
            <v-row>
                <v-col cols="8">
                    <v-text-field v-model="actividad.nombre" readonly hide-details autocomplete="off" label="Nombre" outlined></v-text-field>
                </v-col>

                <v-col cols="4">
                    <v-text-field v-model="actividad.fecha_entrega" readonly hide-details autocomplete="off" label="Fecha de Entrega" outlined></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-textarea rows="3" v-model="actividad.descripcion" readonly hide-details autocomplete="off" label="Descripción" outlined></v-textarea>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="actividad.punteo" readonly hide-details autocomplete="off" label="Punteo" type="number" outlined></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="actividad.bimestre" readonly hide-details autocomplete="off" label="Bimestre" type="number" outlined></v-text-field>
                </v-col>
            </v-row>
            
            <v-card v-if="actividad.adjuntos" class="mt-4" outlined>
                <v-card-title>Documentos Adjuntos </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Documento
                                </th>
                                <th class="text-left">
                                    Acción
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(adjunto, index) in actividad.adjuntos"
                                :key="index"
                            >
                                <td>{{ adjunto.nombre_archivo }}
                                    <v-chip v-if="adjunto.delete" x-small color="red" dark>
                                        ELIMINAR
                                    </v-chip>
                                </td>
                                <td>
                                
                                    <v-btn :href="api_url_adjunto + adjunto.id" icon color="primary">
                                        <v-icon>
                                            mdi-download
                                        </v-icon>
                                    </v-btn>
                                    
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>

            <v-card v-if="!actividad.entrega" class="mt-4" outlined>
                <v-card-title>Subir Actividad </v-card-title>
                <v-card-text>
                    <v-file-input
                        label="Seleccione un archivo"
                        v-model="actividad.archivo"
                        :rules="[v => !!v || 'Campo obligatorio!']"
                    ></v-file-input>
                </v-card-text>
            </v-card>

            <v-card v-if="actividad.entrega" class="mt-4" outlined>
               
                <v-card-text class="pt-0 mt-0">
                    
                    <v-row class="mt-4">
                        <v-col cols="4">
                            <v-alert type="success" dark dense text>
                                Actividad Entregada    
                            </v-alert> 
                        </v-col>
                        <v-col>
                            <v-btn small v-if="actividad.entrega.archivo" :href="api_url + actividad.entrega.id">Descargar 
                                <v-icon>
                                    mdi-download
                                </v-icon>
                            </v-btn>

                            <v-btn :disabled="actividad.entrega.calificacion ? true : false" @click="eliminar_entrega(actividad.entrega)" class="ml-2" small color="error" v-if="actividad.entrega.archivo">ELIMINAR 
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                </v-card-text>
                <v-card-text class="mt-0 pt-0">
                    <v-row align="center">
                        <v-col cols="9">
                            <v-textarea readonly v-model="actividad.entrega.observaciones" hide-details outlined rows="3" label="Observaciones"></v-textarea>
                        </v-col>
                        <v-col>
                            <v-text-field readonly v-model="actividad.entrega.calificacion" hide-details outlined label="Punteo"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-row class="mt-4" align="center" justify="center">
                <v-col align="center" cols="12">
                    <v-btn
						color="secondary"
						@click="() => { this.$emit('closeModal') }"
					>
						Cancelar
					</v-btn>

					<v-btn
						color="primary"
                        class="ml-2"
						type="submit"
                        :disabled="isUploading || actividad.entrega ? true : false"
                        :loading="isUploading"
					>
						Aceptar
					</v-btn>
                </v-col>
            </v-row>

        </v-form>
    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        props: {
            enableEdit: Boolean,
            idItem: Number
        },
        data(){

            return{
                actividad: {
                    nombre: null,
                    descripcion: null,
                    fecha_entrega: null,
                    punteo: null,
                    bimestre: null
                },
                valid: true,
                api_url: process.env.VUE_APP_API_URL + 'descargar_archivo/',
                api_url_adjunto: process.env.VUE_APP_API_URL + 'descargar_adjunto/',
                isUploading: false
            }

        },
        methods: {

            registrar(){

                this.$refs.form.validate()

                if (this.valid) {

                    this.isUploading = true

                    let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                    this.actividad.alumno_id = usuario.id_persona

                    let headers = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }

                    let formData = new FormData();
                    formData.append('files', this.actividad.archivo);
                    formData.append('alumno_id', this.actividad.alumno_id);
                    formData.append('actividad_id', this.actividad.id);

                    this.axios.post(process.env.VUE_APP_API_URL + "entregar_actividad", formData, headers)
                    .then((response) => {
                        
                        if (response.data) {
                            
                            console.log(response.data)

                            Swal.fire({
                                title: 'Excelente!',
                                text: 'La entrega a sigo registrada exitosamente',
                                icon: 'success',
                            }).then(() => {
                                                                
                                //this.$emit('closeModal')
                                this.$emit('updateTable') 
                                this.obtener_detalle()
                                this.isUploading = false

                            })

                        }

                    })

                }

            },
            editar(){

                this.$refs.form.validate()

                if (this.valid) { 

                    this.axios.post(process.env.VUE_APP_API_URL + "editar_actividad", this.actividad)
                    .then((response) => {
                        
                        if (response.data) {
                                
                            Swal.fire({
                                title: 'Excelente!',
                                text: 'La actividad a sido editada exitosamente',
                                icon: 'success',
                            }).then(() => {

                                this.$emit('closeModal')
                                this.$emit('updateTable') 

                            })

                        }

                    })

                }

            },
            obtener_detalle(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {

                    id: this.idItem,
                    alumno_id: usuario.id_persona

                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_actividad_entrega", data)
                .then((response) => {
                    
                    this.actividad = response.data

                })


            },
            descargar(id){


                this.axios.get(process.env.VUE_APP_API_URL + "descargar_archivo/" + id)
                .then((response) => {
                    
                    console.log(response.data);

                })

            },
            eliminar_entrega(entrega){

                Swal.fire({
                    title: '¿Está seguro?',
                    text: "Una vez eliminada no se podrá recuperar!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, ELIMINAR!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {

                    if (result.isConfirmed) {

                        this.axios.post(process.env.VUE_APP_API_URL + "eliminar_entrega", entrega)
                        .then((response) => {

                            if (response.data) {
                                
                                Swal.fire({
                                    title: 'Excelente!',
                                    text: 'La entrega a sido eliminada exitosamente',
                                    icon: 'success',
                                }).then(() => {

                                    this.obtener_detalle()

                                })

                            }
                        })

                    }   

                })

            }

        },
        watch: {

            idItem: function(val){

                if (val) {
                    
                    this.obtener_detalle()

                }

            }

        },
        mounted(){

            if (this.enableEdit) {
                
                this.obtener_detalle()

            }

        }

    }
</script>