import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Menu from '../views/Menu.vue'

// Menu de Administrador o Propietario
import Alumnos from '../views/Alumnos.vue'
import Grados from '../views/Grados.vue'
import Administracion from '../views/Administracion.vue'
import Reportes from '../views/Reportes.vue'

// Adminitración
import Usuarios from '../views/administracion/Usuarios.vue'
import Colaboradores from '../views/administracion/Colaboradores.vue'
import GradosAdmin from '../views/administracion/Grados.vue'
import Cursos from '../views/administracion/Cursos.vue'
import Pagos from '../views/administracion/Pagos'
import Sedes from '../views/administracion/Sedes.vue'
import Ciclos from '../views/administracion/Ciclos.vue'
import Boleta from '../views/administracion/Boleta.vue'
import AnunciosAdmin from '../views/administracion/Anuncios.vue'
import Jornadas from '../views/administracion/Jornadas.vue'

//Alumnos
import DetalleAlumno from '../views/alumnos/DetalleAlumno.vue'

//Grados
import DetalleGrado from '../views/grados/DetalleGrado'

//Grados Predeterminados
import DetalleGradoPred from '../views/grados_pred/DetalleGrado'

// Perfil del Alumno
import CursosAlumno from '../views/CursosAlumno'
import CursoAlumno from '../views/CursoAlumno'
import Anuncios from '../views/Anuncios'

// Perfil del maestro
import CursosMaestro from '../views/CursosMaestro'
import CursoMaestro from '../views/CursoMaestro'

// Perfil del encargado
import AlumnosEncargado from '../views/AlumnosEncargado'
import DetalleAlumnoEncargado from '../views/DetalleAlumnoEncargado'

// Home
import Inicio from '../views/home/Home'
import Canalitos from '../views/home/Canalitos'
import Inco from '../views/home/Inco'
import Liceo from '../views/home/Liceo'
import Piedra from '../views/home/Piedra'
import Requisitos from '../views/home/Requisitos'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'start',
		component: Inicio,
		children: [
			{
				path: '/sedes/canalitos',
				name: 'canalitos',
				component: Canalitos,
			},
			{
				path: '/sedes/inco',
				name: 'inco',
				component: Inco,
			},
			{
				path: '/piedra_parada',
				name: 'piedra_parada',
				component: Piedra,
			},
			{
				path: '/liceo',
				name: 'liceo',
				component: Liceo,
			},
			{
				path: '/admisiones',
				name: 'admisiones',
				component: Requisitos,
			}
		]
	},
	{
		path: '/plataforma',
		name: 'login',
		component: Login
	},
	{
		path: '/home',
		name: 'home',
		component: Home,
		children: [
			{
				path: '/home/menu',
				name: 'menu',
				component: Menu
			},
			{
				path: '/home/alumnos',
				name: 'alumnos',
				component: Alumnos
			},
			{
				path: '/home/alumnos/detalle/:id',
				name: 'detalle_alumno',
				component: DetalleAlumno
			},
			{
				path: '/home/grados',
				name: 'grados',
				component: Grados
			},
			{
				path: '/home/grados/detalle/:id',
				name: 'detalle_grado',
				component: DetalleGrado
			},
			{
				path: '/home/administracion',
				name: 'administracion',
				component: Administracion
			},
			{
				path: '/home/administracion/usuarios',
				name: 'usuarios',
				component: Usuarios
			},
			{
				path: '/home/administracion/colaboradores',
				name: 'colaboradores',
				component: Colaboradores
			},
			{
				path: '/home/administracion/grados',
				name: 'grados_admin',
				component: GradosAdmin
			},
			{
				path: '/home/administracion/grados/detalle/:id',
				name: 'detalle_grado_pred',
				component: DetalleGradoPred
			},
			{
				path: '/home/administracion/cursos',
				name: 'cursos',
				component: Cursos
			},
			{
				path: '/home/administracion/pagos',
				name: 'pagos',
				component: Pagos
			},
			{
				path: '/home/administracion/sedes',
				name: 'sedes',
				component: Sedes
			},
			{
				path: '/home/administracion/jornadas',
				name: 'jornadas',
				component: Jornadas
			},
			{
				path: '/home/administracion/ciclos',
				name: 'ciclos',
				component: Ciclos
			},
			{
				path: '/home/administracion/boleta',
				name: 'boleta',
				component: Boleta
			},
			{
				path: '/home/administracion/anuncios',
				name: 'anuncios_admin',
				component: AnunciosAdmin
			},
			{
				path: '/home/reportes',
				name: 'reportes',
				component: Reportes
			},
			// Perfil del alumno
			{
				path: '/home/cursos_alumno',
				name: 'cursos_alumno',
				component: CursosAlumno
			},
			{
				path: '/home/cursos_alumno/detalle/:id',
				name: 'curso_alumno',
				component: CursoAlumno
			},
			{
				path: '/home/anuncios',
				name: 'anuncios',
				component: Anuncios
			},
			// Perfil del maestro
			{
				path: '/home/cursos_maestro',
				name: 'cursos_maestro',
				component: CursosMaestro
			},
			{
				path: '/home/cursos_maestro/detalle/:id',
				name: 'curso_maestro',
				component: CursoMaestro
			},
			// Perfil del encargado
			{
				path: '/home/alumnos_encargado',
				name: 'encargado_alumnos',
				component: AlumnosEncargado
			},
			{
				path: '/home/alumnos_encargado/detalle/:id',
				name: 'detalle_alumno_encargado',
				component: DetalleAlumnoEncargado
			},
			
		]
	},
]

const router = new VueRouter({
	routes
})

export default router
