<template>
    <div>

        <v-row class="mt-4" align="center" justify="center">
            <v-col cols="6">
                <v-text-field autocomplete="off" v-model="descuento" prepend-inner-icon="mdi-sale" hide-details outlined label="Descuento"></v-text-field>
            </v-col>
        </v-row>

        <v-row class="mt-4" align="center" justify="center">
            <v-col align="center" cols="12">
                <v-btn
                    color="secondary"
                    @click="() => { this.$emit('closeModal') }"
                >
                    Cancelar
                </v-btn>

                <v-btn
                    color="primary"
                    class="ml-2"
                    :disabled="!descuento"
                    @click="registrar_descuento()"
                >
                    Aceptar
                </v-btn>
            </v-col>
        </v-row>

    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        
        data(){
            return{
                descuento: null
            }
        },
        methods: {

            obtener_descuento(){

                let data = {
                    id_alumno: this.$route.params.id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "obtener_descuento", data)
                .then((response) => {
                    
                    this.descuento = response.data
                    
                })
                
            },
            registrar_descuento(){

                let data = {
                    id_alumno: this.$route.params.id,
                    descuento: this.descuento
                }

                this.axios.post(process.env.VUE_APP_API_URL + "registrar_descuento", data)
                .then((response) => {
                    
                    console.log(response.data);

                    Swal.fire({
                        title: 'Excelente!',
                        text: 'El alumno a sido asignado al grado exitosamente',
                        icon: 'success',
                    }).then(() => {

                        this.$emit('actualizarDetalle')
                        this.$emit('closeModal')

                    })
                    
                })

            }

        },
        mounted(){

            this.obtener_descuento()

        }
    }
</script>