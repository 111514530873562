<template>
	<div>
		<v-container fluid>
			<v-row>
				<v-col v-for="(alumno, index) in alumnos" :key="index" md="3" lg="3" cols="12">
					<v-card
						outlined
					>
						<v-list-item three-line>
							<v-list-item-content>
								<!-- <div class="overline mb-4">
								OVERLINE
								</div> -->
								<v-list-item-title class="mb-2">
									{{ alumno.nombre }} {{ alumno.segundo_nombre }} {{ alumno.apellido }} {{ alumno.segundo_apellido }}
								</v-list-item-title>
								
									<small>
										<strong>Grado:</strong> {{ alumno.grado.grado }} 
									</small>
									<small>
										<strong>Nivel:</strong> {{ alumno.grado.nivel }}
									</small>
									<small>
										<strong>Sección:</strong> {{ alumno.grado.seccion }}
									</small>
									<small>
										<strong>Jornada:</strong> {{ alumno.grado.jornada }}
									</small>
									<small>
										<strong>Ciclo:</strong> {{ alumno.grado.ciclo_escolar }}
									</small>
							</v-list-item-content>

							<v-list-item-avatar size="100">
								<v-img src="@/assets/img/student.png"></v-img>
							</v-list-item-avatar>
						</v-list-item>

						<v-card-actions>
							<v-btn color="orange" icon>
								<v-icon>
									mdi-bell
								</v-icon>
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn :to="{ name: 'detalle_alumno_encargado', params: { id: alumno.id } }" color="primary" text>
								INGRESAR
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	export default {

		data(){
			return{
				alumnos: []
			}
		},
		methods: {

			obtener_alumnos(){

				const local = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {

                    id_usuario: local.id_usuario

                }

                this.axios.post(process.env.VUE_APP_API_URL + "alumnos_encargado", data)
                .then((response) => {
                    
					this.alumnos = response.data


                })

            }

		},
		mounted(){

			this.obtener_alumnos()

		}

	}
</script>

<style>

</style>