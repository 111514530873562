<template>
	<div class="home">
		<v-app>
			<v-app-bar app color="teal darken-1" dark >
				<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
				<v-toolbar-title>
					<v-list-item-content>
						<v-list-item-title>
							<h3>Plataforma Educativa</h3>
						</v-list-item-title>
						<v-list-item-subtitle>{{ userData.sede }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn @click="mostrar_modal()" icon>
					<v-icon>
						mdi-account-cog
					</v-icon>
				</v-btn>
			</v-app-bar>

			<v-navigation-drawer
				color="teal darken-1"
				dark
				:expand-on-hover="$vuetify.breakpoint.mdAndUp && drawer ? true : false"
				v-model="drawer"
				:permanent="$vuetify.breakpoint.mdAndUp"
				app			
			>
				<template v-slot:prepend>
					<v-list-item class="px-2" two-line>
					<v-list-item-avatar>
						<img src="@/assets/img/user.png">
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>{{ userData.nombre }}</v-list-item-title>
						<v-list-item-subtitle>{{ userData.tipo_usuario }}</v-list-item-subtitle>
					</v-list-item-content>
					</v-list-item>
				</template>

				<v-divider></v-divider>

				<v-list
					nav
					dense
				>
					<v-list-item :to="{name: item.url}" v-for="(item, key) in menu" :key="key" link>
						<v-list-item-icon>
						<v-icon>{{ item.icono }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ item.nombre }}</v-list-item-title>
					</v-list-item>
			
				</v-list>

				<template v-slot:append>
					<v-list-item @click="salir()" link>
						<v-list-item-icon>
						<v-icon>mdi-logout</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Salir</v-list-item-title>
					</v-list-item>
				</template>
			</v-navigation-drawer>

			<v-container fluid>
				<v-main>
				<router-view></router-view>
				</v-main>
			</v-container>

			<Modal :modalShow="show_modal" :titleModal="title" :sizeModal="size">
				<template #form-modal>
					<Form @closeModal="show_modal = false"></Form>
				</template>
			</Modal>
		</v-app>

		
	</div>
</template>

<script>

	import Modal from '@/components/Modal'
	import Form from '@/components/FormClave'

	export default {
		components: {
			Modal,
			Form
		},
		name: 'Home',
		data(){
			return{

				drawer: false,
				menu: [],
				show_modal: false,
				title: null,
				size: null

			}
		},
		methods: {
			
			obtener_menu(){

				let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

				this.axios.post(process.env.VUE_APP_API_URL + "obtener_menu", usuario)
				.then((response) => {
					
					this.menu = response.data

				})

			},
			salir(){

				// Enviar petición para eliminar el token de notificaciones
				const local = JSON.parse(localStorage.getItem('plataforma-sinai'))

				let data = {
					id_usuario: local.id_usuario
				}

				this.axios.post(process.env.VUE_APP_API_URL + "logout", data)
				.then((response) => {
					
					console.log(response.data)

				})

				data = {
					token: local.token
				}

				localStorage.setItem('plataforma-sinai', JSON.stringify(data))

				this.$router.push({ name: 'login' })

			},
			mostrar_modal(){

				this.show_modal = true
				this.title = "Cambiar Contraseña"
				this.size = "500"

			}
		},
		computed:{

			userData: function(){

				let data = JSON.parse(localStorage.getItem('plataforma-sinai'))

				return data;

			}

		},
		mounted(){

			this.obtener_menu()

		}
	}
</script>
