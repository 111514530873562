<template>
    <div>
        <v-form ref="form" @submit.prevent="!editMode ? registrar() : editar()" v-model="valid">
            <v-row class="mt-4">
                <v-col cols="12">
                    <v-text-field v-model="jornada.nombre" :rules="[v => !!v || 'Campo obligatorio!']" autocomplete="off" hide-details label="Nombre" outlined></v-text-field>
                </v-col>

            </v-row>

            <v-row class="mt-4" align="center" justify="center">
                <v-col align="center" cols="12">
                    <v-btn
                        color="secondary"
                        @click="() => { this.$emit('closeModal') }"
                    >
                        Cancelar
                    </v-btn>

                    <v-btn
                        color="primary"
                        class="ml-2"
                        type="submit"
                    >
                        Aceptar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>

    // eslint-disable-next-line no-unused-vars
    import Swal from 'sweetalert2'
    
    export default {
        props: {
            idItem: Number,
            editMode: Boolean
        },
        data(){
            return{
                jornada: {
                    nombre: null,
                },
                valid: true
            }
        },
        methods: {

            registrar(){

                this.$refs.form.validate()

                if (this.valid) {

                    let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                    this.jornada.sede_id = usuario.sede_id

                    this.axios.post(process.env.VUE_APP_API_URL + "registrar_jornada", this.jornada)
                    .then((response) => {
                        
                        if (response.data) {
                            
                            Swal.fire({
                                title: 'Excelente!',
                                text: 'La jornada a sigo registrada exitosamente',
                                icon: 'success',
                            }).then(() => {

                                this.$emit('closeModal')
                                this.$emit('updateTable') 

                            })

                        }

                    })

                }

            },
            obtener_detalle(){

                let data = {

                    id: this.idItem

                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_jornada", data)
                .then((response) => {
                    
                    this.jornada = response.data

                })

            },
            editar(){

                this.$refs.form.validate()

                if (this.valid) { 

                    this.axios.post(process.env.VUE_APP_API_URL + "editar_jornada", this.jornada)
                    .then((response) => {
                        
                        if (response.data) {
                                
                            Swal.fire({
                                title: 'Excelente!',
                                text: 'La jornada a sido editada exitosamente',
                                icon: 'success',
                            }).then(() => {

                                this.$emit('closeModal')
                                this.$emit('updateTable') 

                            })

                        }

                    })

                }

            }

        },
        watch: {

            idItem: function(val){

                if (val) {
                    
                    this.obtener_detalle()

                }

            }

        },
        mounted(){

            if (this.editMode) {
                
                this.obtener_detalle()

            }

        }
    }
</script>