<template>
	<div>
		<v-card>
			<v-card-text>
				<v-row align="center" dense>
					<v-col cols="2">
						<v-menu
							ref="menu"
							v-model="menu"
							:close-on-content-click="false"
							:return-value.sync="date"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="date"
									label="Mes"
									prepend-inner-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
									hide-details
									outlined
								></v-text-field>
							</template>
							<v-date-picker
							v-model="date"
							type="month"
							no-title
							scrollable
							>
							<v-spacer></v-spacer>
							<v-btn
								text
								color="primary"
								@click="menu = false"
							>
								Cancelar
							</v-btn>
							<v-btn
								text
								color="primary"
								@click="change_date(date)"
							>
								OK
							</v-btn>
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col align="end">
						<v-btn @click="mostrar_detalle()" color="primary">
							VER DETALLE 
							<v-icon>
								mdi-file-table
							</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-text>
				<v-row>
					<v-col cols="12">
						<highcharts :options="chartOptions"></highcharts>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<Modal @closeModal="closeModal" :modalShow="modalShow" :titleModal="titleModal" :sizeModal="sizeModal">
			<template #form-modal>
				<DetalleReporte :load="load" :date="date" :day="day"></DetalleReporte>
			</template>
		</Modal>
	</div>
</template>

<script>

	import Modal from '@/components/Modal'
	import DetalleReporte from '@/components/reportes/DetalleReporte'

	export default {
		components: {
			Modal,
			DetalleReporte
		},	
		data() {
			return {
				date: new Date().toISOString().substr(0, 7),
				menu: false,
				modalShow: false,
				titleModal: null,
				sizeModal: null,
				chartOptions: {
					chart: {
						type: 'column'
					},
					title: {
						text: null
					},
					subtitle: {
						text: null
					},
					xAxis: {
						type: 'category',
						labels: {
							style: {
								fontSize: '13px',
								fontFamily: 'Verdana, sans-serif'
							}
						}
					},
					yAxis: {
						min: 0,
						title: {
							text: 'Ingresos'
						}
					},
					legend: {
						enabled: false
					},
					tooltip: {
						pointFormat: 'Ingresos por día: <b>Q{point.y:.1f}</b>'
					},
					series: [{
						name: 'Ingresos',
						data: [],
						dataLabels: {
							enabled: true,
							rotation: 0,
							color: '#000000',
							align: 'right',
							format: '{point.y}', // one decimal
							y: 0, // 10 pixels down from the top
							style: {
								fontSize: '10px',
								fontFamily: 'Verdana, sans-serif'
							}
						}
					}],
					plotOptions: {
						series: {
							opacity: 0.8,
							cursor: 'pointer',
							point: {
								events: {
									click: (event) => {
										this.mostrar_detalle_dia(event.point.category)
									}
								}
							}
						}
					}
				},
				day: null,
				load: false
			}
		},
		methods: {

			obtener_datos(){

				const usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

				const data = {
					fecha: this.date,
					sede_id: usuario.sede_id
				}

				this.axios.post(process.env.VUE_APP_API_URL + "grafica_mensual", data)
				.then((response) => {
					
					this.chartOptions.series[0].data = response.data.chart_data
					this.chartOptions.title.text = response.data.title
					this.chartOptions.subtitle.text = response.data.subtitle

				})

			},
			change_date(date){

				this.$refs.menu.save(date)
				this.obtener_datos()

			},
			mostrar_detalle(){

				this.modalShow = true
				this.titleModal = 'Detalle de Ingresos'
				this.sizeModal = '1200'
				this.day = null
				this.load = true

			},
			mostrar_detalle_dia(day){

				this.modalShow = true
				this.titleModal = 'Detalle de Ingresos Diario'
				this.sizeModal = '1200'
				this.day = day
				this.load = true

			},
			closeModal(){

				this.modalShow = false
				this.load = false

			}

		},
		watch: {

			

		},
		mounted(){

			this.obtener_datos()

		}
	}
</script>

<style>

</style>