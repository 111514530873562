<template>
    <div>
		<v-container class="background" fluid fill-height>
			<v-row style="height: 100vh" align="center" justify="center">
				<v-col cols="12" md="6" sm="10">
					<v-card color="rgb(0, 0, 0, 0.2)" shaped class="pl-4 pr-4" elevation="4">

						<v-card-title class="justify-center">
							<h2 class="white--text mb-4 mt-4">Inicio de Sesión</h2>
						</v-card-title>

						<v-card-text>
							<v-row align="center" justify="center">
								<v-col cols="3">
									<v-img
										width="150"
										src="@/assets/img/online-lesson.png"
									></v-img>
								</v-col>
							</v-row>
						</v-card-text>
							
						<v-card-text>

							<v-form @submit.prevent="doLogin()" ref="form_login" v-model="valid_form">
								<v-row align="center" justify="center">
									<v-col cols="12" md="6" sm="6">
										<v-text-field autocomplete="off" :rules="[v => !!v || 'Campo obligatorio!']" v-model="login.usuario" hide-details solo label="Usuario" prepend-inner-icon="mdi-account"></v-text-field>
									</v-col>

								</v-row>
								<v-row align="center" justify="center">
									<v-col cols="12" md="6" sm="6">
										<v-text-field autocomplete="off" :rules="[v => !!v || 'Campo obligatorio!']" v-model="login.password" type="password" hide-details solo label="Contraseña" prepend-inner-icon="mdi-lock"></v-text-field>
									</v-col>
								</v-row>

								<v-row justify="center" class="mt-4">
									<v-col cols="4">
										<v-btn :disabled="loading" :loading="loading" type="submit" x-large color="blue darken-4" dark block>INGRESAR</v-btn>
									</v-col>
								</v-row>
							</v-form>

						</v-card-text>
						
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style scoped>
	.background{

		background-size: cover;
		background-image: url('~@/assets/img/background.jpeg');

	}
</style>

<script>

	import Swal from 'sweetalert2'

	export default {
		data(){
			return{
				login: {
					usuario: null,
					password: null
				},
				loading: false,
				valid_form: true
			}
		},
		methods: {

			doLogin(){

				this.$refs.form_login.validate()
				
				if (this.valid_form) {
					
					this.loading = true

					const local = JSON.parse(localStorage.getItem('plataforma-sinai'))
										
					this.login.token = local ? local.token : null

					this.axios.post(process.env.VUE_APP_API_URL + "login", this.login)
					.then((response) => {

						console.log(response.data)

						this.loading = false
						
						if (response.data.code != 200) {
							
							Swal.fire({
								title: 'Error!',
								text: response.data.message,
								icon: 'error',
							})

						}else{

							let data = JSON.parse(localStorage.getItem('plataforma-sinai'))

							if (!data) {
								
								data = {}

							}
							// eslint-disable-next-line no-unused-vars

							data.id_usuario = response.data.data.id,
							data.tipo_usuario_id = response.data.data.tipo_usuario_id,
							data.nombre = response.data.data.persona.nombre + ' ' + response.data.data.persona.apellido,
							data.id_persona =  response.data.data.persona.id,
							data.tipo_usuario = response.data.data.tipo_usuario.tipo,
							data.sede_id = response.data.data.sede.id,
							data.sede = response.data.data.sede.nombre
							
							
							localStorage.setItem('plataforma-sinai', JSON.stringify(data))

							this.$router.push({ name: 'menu'})

						}

					})

				}
				

			}

		}		
	}
</script>