<template>
    <div>
        <v-form ref="form" @submit.prevent="!editMode ? registrar() : editar()" v-model="valid">
            <v-row class="mt-4">
                <v-col cols="12" md="12">
                    <v-text-field v-model="grado.nombre" :rules="[v => !!v || 'Campo obligatorio!']" autocomplete="off" hide-details label="Nombre" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-select v-model="grado.nivel_id" :items="niveles" :rules="[v => !!v || 'Campo obligatorio!']" item-text="nombre" item-value="id" autocomplete="off" hide-details label="Nivel" outlined></v-select>
                </v-col>

                <v-col cols="6">
                    <v-select v-model="grado.jornada_id" :items="jornadas" :rules="[v => !!v || 'Campo obligatorio!']" item-text="nombre" item-value="id" autocomplete="off" hide-details label="Jornada" outlined></v-select>
                </v-col>

                <v-col cols="6">
                    <v-checkbox class="mt-0" hide-details :false-value="0" :true-value="1" label="Graduando" v-model="grado.graduando"></v-checkbox>
                </v-col>

            </v-row>

            <v-row class="mt-4" align="center" justify="center">
                <v-col align="center" cols="12">
                    <v-btn
                        color="secondary"
                        @click="() => { this.$emit('closeModal') }"
                    >
                        Cancelar
                    </v-btn>

                    <v-btn
                        color="primary"
                        class="ml-2"
                        type="submit"
                    >
                        Aceptar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        props: {
            idItem: Number,
            editMode: Boolean
        },
        data(){
            return{
                tipos_empleados: [],
                valid: true,
                grado: {
                    nombre: null,
                    nivel_id: null,
                    jornada_id: null,
                    sede_id: null
                },
                jornadas: [],
                niveles: []
            }
        },
        methods: {

            obtener_datos_registro(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {

                    sede_id: usuario.sede_id

                }

                this.axios.post(process.env.VUE_APP_API_URL + "obtener_datos_registro_grado_pred", data)
                .then((response) => {
                    
                    this.jornadas = response.data.jornadas
                    this.niveles = response.data.niveles

                })

            },
            registrar(){
                
                this.$refs.form.validate()

                if (this.valid) {

                    let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                    this.grado.sede_id = usuario.sede_id

                    this.axios.post(process.env.VUE_APP_API_URL + "registrar_grado_pred", this.grado)
                    .then((response) => {
                        
                        if (response.data) {
                            
                            console.log(response.data);

                            Swal.fire({
                                title: 'Excelente!',
                                text: 'El grado a sido registrado exitosamente',
                                icon: 'success',
                            }).then(() => {

                                this.$emit('closeModal')
                                this.$emit('updateTable') 

                            })

                        }

                    })

                }

            },
            obtener_detalle(){

                let data = {

                    id: this.idItem

                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_grado_pred", data)
                .then((response) => {
                    
                    this.grado = response.data

                })

            },
            editar(){

                this.axios.post(process.env.VUE_APP_API_URL + "editar_grado_pred", this.grado)
                .then((response) => {
                    
                    if (response.data) {
                            
                        Swal.fire({
                            title: 'Excelente!',
                            text: 'El grado a sido editado exitosamente',
                            icon: 'success',
                        }).then(() => {

                            this.$emit('closeModal')
                            this.$emit('updateTable') 

                        })

                    }

                })

            }
        },
        watch: {

            idItem: function(val){

                if (val) {
                    
                    this.obtener_detalle()

                }

            }

        },
        mounted(){

            if (this.editMode) {
                
                this.obtener_detalle()

            }

            this.obtener_datos_registro()

        }

    }
</script>