<template>
    <div>

        <v-container class="mt-4" fluid>
             <v-row class="mb-4" align="center">
                <v-col md="4">
                    <v-text-field autocomplete="off" hide-details v-model="busqueda" label="Buscar..." prepend-inner-icon="mdi-account-search" clearable outlined></v-text-field>
                </v-col>
                <v-col align="end">
                    <!-- <v-btn color="primary" class="mr-2">SOLVENCIA 
                        <v-icon>mdi-text-box-check</v-icon>
                    </v-btn> -->
                    <v-btn @click="solvente.length > 0 ? solvencia() : null" :color="solvente.length == 0 ? 'success' : 'red'" dark label class="mr-2">
                        <v-icon left>
                            mdi-check-all
                        </v-icon>
                        {{ solvente.length == 0 ? 'SOLVENTE' : 'INSOLVENTE' }}
                    </v-btn>

                    <v-btn @click="modal_pagos()" color="success">PAGAR 
                        <v-icon>mdi-cash</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="10"
                        class="elevation-2"
                        :search="busqueda"
                    >
                    
                        <template v-slot:[`item.action`]="data">

                            <v-btn @click="eliminar_pago(data.item)" icon xs :color="!data.item.deleted_at ? 'red' : 'primary'">
                                <v-icon>
                                    {{ !data.item.deleted_at ? 'mdi-delete' : 'mdi-reload' }}
                                </v-icon>
                            </v-btn>

                        </template>

                        <template v-slot:[`item.recibo_id`]="data">

                            {{ data.item.serie }} - {{ data.item.recibo_id }}
                            <v-btn v-if="!data.item.deleted_at" @click="descargar_recibo(data.item.recibo_id, data.item.serie)" icon x-small color="primary">
                                <v-icon>mdi-download</v-icon>
                            </v-btn>

                        </template>

                        <template v-slot:[`item.estado`]="data">
                            <v-chip v-if="data.item.deleted_at" color="red" label x-small dark>
                                ELIMINADO
                            </v-chip>
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

        </v-container>

        <Modal @closeModal="modalShow = false" :titleModal="titleModal" :sizeModal="sizeModal" :modalShow="modalShow">
            <template #form-modal>
                <Form @closeModal="modalShow = false" @updateTable="obtener_datos" :alumno="alumno"></Form>
            </template>
        </Modal>

        <Modal @closeModal="modalShowSolvencia = false" :titleModal="titleModal" :sizeModal="sizeModal" :modalShow="modalShowSolvencia">
            <template #form-modal>
                <FormSolvencia :alumno="alumno" :pagos="solvente"></FormSolvencia>
            </template>
        </Modal>

    </div>
</template>

<script>

    import Modal from '@/components/Modal.vue'
    import Form from '@/components/pagos/Form.vue'
    import FormSolvencia from '@/components/Alumnos/FormSolvencia'

    import Swal from 'sweetalert2'

    export default {
        props: {
            alumno: Object
        },
        components: {
            Modal,
            Form,      
            FormSolvencia      
        },
        data(){
            return{
                data_url: process.env.VUE_APP_API_URL + "obtener_pagos_alumno",
                busqueda: null,
                headers: [],
                items: [],
                fab: false,
                breadcrumbs: [
                    {
                        text: 'Administración',
                        href: '#/home/administracion',
                    },
                    {
                        text: 'Usuarios',
                        active: true
                    },
                ],
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                idRecibo: null,
                solvente: [],
                modalShowSolvencia: false,
            }
        },
        methods: {

            obtener_datos(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {
                    id_alumno: this.$route.params.id,
                    sede_id: usuario.sede_id
                }

                this.axios.post(this.data_url, data)
                .then((response) => {

                    this.headers = response.data.headers
                    this.items = response.data.items
                    this.solvente = response.data.solvente

                })

            },
            modal_pagos(){

                this.titleModal = "Generar Pago"
                this.sizeModal = '1200'
                this.modalShow = true

            },
            descargar_recibo(id, serie){

                let url = serie == 'A' ? 'generar_recibo' : 'generar_recibo_otros'

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {

                    alumno_id: this.$route.params.id,
                    sede_id: usuario.sede_id,
                    recibo_id: id
                }


                this.axios.post(process.env.VUE_APP_API_URL + url, data, { responseType: 'blob' })
                .then((response) => {
                    
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', id + '.pdf');
                    document.body.appendChild(link);
                    link.click();

                })

            },
            eliminar_pago(item){

                Swal.fire({
                    title: '¿Está seguro?',
                    text: "El estado del pago será actualizado!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, ELIMINAR!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {

                        let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                        let data = {

                            alumno_id: this.$route.params.id,
                            sede_id: usuario.sede_id,
                            id: item.id,
                            tipo: item.tipo
                        }

                         this.axios.post(process.env.VUE_APP_API_URL + 'eliminar_pago', data)
                        .then((response) => {

                            if (response.data) {
                                
                                Swal.fire(
                                    'Excelente!',
                                    'El pago a sido actualizado exitosamente',
                                    'success'
                                ).then(() => {
                                    this.obtener_datos()
                                })

                            }

                        })

                        
                    }
                })
 
            },
            solvencia(){

                console.log('solvencia');

                this.titleModal = "Pagos Pendientes"
                this.sizeModal = '500'
                this.modalShowSolvencia = true

            }

        },
        mounted(){

            this.obtener_datos()

        }             
    }
</script>