<template>
    <div>
        <v-row justify="center">
            
            <v-col cols="10">
                <v-card>
                    <v-toolbar
                        flat
                        color="secondary"
                        dark
                    >
                    <v-toolbar-title>Recibo</v-toolbar-title>
                    </v-toolbar>
                    
                    <v-card-text>
                        <v-row>
                            <v-col cols="8">
                                <v-text-field v-model="alumno.nombre_completo" readonly hide-details dense outlined label="Alumno"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field readonly v-model="alumno.ciclo" hide-details dense outlined label="Ciclo"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field readonly v-model="alumno.grado" hide-details dense outlined label="Grado"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field readonly v-model="alumno.nivel" hide-details dense outlined label="Nivel"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field readonly v-model="alumno.seccion" hide-details dense outlined label="Sección"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text class="mt-0 pt-0">
                        <v-card flat>
                            <v-card-text>
                                <v-row align="center" dense>
                                    <v-col><h2>Agregar Pagos</h2></v-col>
                                    <v-col md="3" align="end">
                                        <v-btn v-if="alumno.descuento" dense text color="warning"><v-icon left>
                                            mdi-sale</v-icon> BECADO {{ alumno.descuento }} %</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-text>
                                <v-card outlined>
                                    <v-card-text>
                                        <v-row dense> 
                                            <v-col md="4">
                                                <v-select v-model="tipo_pago" dense hide-details outlined label="Tipo de Pago" :items="tipos_pagos" item-text="text" item-value="id"></v-select>
                                            </v-col>

                                            <v-col md="4" v-if="tipo_pago == 'mensualidad'">
                                                <v-select v-model="pago_select" :items="pagos" dense hide-details outlined label="Mes" item-text="nombre" item-value="id"></v-select>
                                            </v-col>

                                            <v-col md="4" v-if="tipo_pago == 'mora'">
                                                <v-text-field v-model="concepto_mora" dense hide-details outlined label="Concepto"></v-text-field>
                                            </v-col>

                                            <v-col md="2" v-if="tipo_pago == 'mora'">
                                                <v-text-field v-model="monto_mora" dense hide-details outlined label="Monto"></v-text-field>
                                            </v-col>

                                            <v-col md="4" v-if="tipo_pago == 'anual'">
                                                <v-select v-model="pago_select" dense hide-details outlined label="Pago Anual" :items="pagos" item-text="nombre" item-value="id"></v-select>
                                            </v-col>

                                            <v-col md="2" v-if="tipo_pago && (pago_select) || (concepto_mora && monto_mora)">
                                                <v-btn @click="agregar_pago()" color="success" icon>
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>

                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                            <th class="text-left">
                                                Concepto
                                            </th>
                                            <th class="text-left">
                                                Monto
                                            </th>
                                            <th class="text-left">
                                                Acción
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(pago, index) in pagos_recibo"
                                                :key="pago.id"
                                            >
                                                <td>{{ pago.nombre }}</td>
                                                <td>Q. {{ pago.monto }}</td>
                                                <td>
                                                    <v-btn @click="eliminar_pago(pago, index)" icon color="red">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>

                                            <tr v-if="pagos_recibo.length > 0">
                                                <td>
                                                    <strong>Total</strong>
                                                </td>
                                                <td>
                                                    <strong>Q. {{ total_recibo }}</strong>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <v-card-text>
                        <v-row justify="center" align="center" dense>
                            <v-col align="center" cols="12">
                                <v-btn
                                    color="secondary"
                                    @click="() => { this.$emit('closeModal') }"
                                >
                                    Cancelar
                                </v-btn>

                                <v-btn
                                    color="primary"
                                    class="ml-2"
                                    :disabled="!total_recibo"
                                    @click="registrar_pagos()"
                                >
                                    Aceptar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        props: {
            alumno: Object
        },
        data(){
            return{
                tipos_pagos: [
                    {
                        text: "Mensualidad",
                        id: 'mensualidad'
                    },
                    {
                        text: 'Moras',
                        id: 'mora'
                    },
                    {
                        text: "Pago Anual",
                        id: "anual"
                    }
                ],
                tipo_pago: null,
                headers: [],
                items: [],
                pagos: [],
                pagos_recibo: [],
                pago_select: null,
                concepto_mora: null,
                monto_mora: null
            }
        },
        methods: {

            agregar_pago(){

                if (this.tipo_pago == 'mensualidad' || this.tipo_pago == 'anual') {
                    
                    let pago = this.pagos.filter( pago => pago.id == this.pago_select )

                    if (pago[0].tipo_pago_id == 1) {
                        
                        pago[0].nombre = "Mensualidad " + pago[0].nombre

                    }

                    this.pagos_recibo.push(pago[0])

                    // Remover el pago de manera temporal
                    this.pagos = this.pagos.filter( pago_temp => pago_temp.id != pago[0].id )

                    this.pago_select = null

                    console.log(this.pagos_recibo)

                }else{

                    let otro_pago = {

                        id: null,
                        monto: this.monto_mora,
                        nombre: this.concepto_mora,
                        tipo_pago_id: 3

                    }

                    this.pagos_recibo.push(otro_pago)

                    this.monto_mora = null
                    this.concepto_mora = null

                }

            },
            registrar_pagos(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {

                    total: this.total_recibo,
                    pagos: this.pagos_recibo,
                    alumno_id: this.alumno.id,
                    sede_id: usuario.sede_id,
                    ciclo_escolar: this.alumno.ciclo
                }

                this.axios.post(process.env.VUE_APP_API_URL + "registrar_pagos", data)
                .then((response) => {
                    
                    if (response.data) {
                            
                        Swal.fire({
                            title: 'Excelente!',
                            text: 'El pago a sido registrado exitosamente',
                            icon: 'success',
                        }).then(() => {

                            let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                            let recibo_id = response.data

                            let data = {

                                alumno_id: this.$route.params.id,
                                sede_id: usuario.sede_id,
                                recibo_id: response.data
                            }

                            this.axios.post(process.env.VUE_APP_API_URL + "generar_recibo", data, { responseType: 'blob' })
                            .then((response) => {
                                
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', recibo_id + '.pdf');
                                document.body.appendChild(link);
                                link.click();

                            })
                            this.$emit('updateTable')
                            this.$emit('closeModal')

                        })
                
                    }

                    console.log(response.data);

                })

            },
            eliminar_pago(pago, index){
                
                this.pagos_recibo.splice(index, 1)

                // Obtener de nuevo las mensualidades
                if (pago.tipo_pago_id == 1 || pago.tipo_pago_id == 2) {
                    
                    let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                    let data = {
                        pago_id: pago.tipo_pago_id,
                        alumno_id: this.alumno.id,
                        grado_id: this.alumno.grado_id,
                        sede_id: usuario.sede_id,
                        ciclo_escolar: this.alumno.ciclo
                    }

                    this.axios.post(process.env.VUE_APP_API_URL + "mensualidades_pendientes", data)
                    .then((response) => {
                        
                        this.pagos = response.data

                    })

                }

            }

        },
        watch: {

            tipo_pago: function(val){

                let id_pago = null

                if (val == 'mensualidad') {
                    
                    id_pago = 1

                }else if(val == 'anual'){

                    id_pago = 2

                }

                if (id_pago != null) {
                    
                    let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                    let data = {
                        pago_id: id_pago,
                        alumno_id: this.alumno.id,
                        grado_id: this.alumno.grado_id,
                        sede_id: usuario.sede_id,
                        ciclo_escolar: this.alumno.ciclo
                    }

                    this.axios.post(process.env.VUE_APP_API_URL + "mensualidades_pendientes", data)
                    .then((response) => {
                        
                        this.pagos = response.data

                    })

                }

            }

        },
        computed: {

            total_recibo: function(){

                let total = 0

                this.pagos_recibo.forEach(pago => {
                    
                    total = total + parseFloat(pago.monto)

                });

                return total

            }

        }
    }
</script>