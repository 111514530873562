<template>
    <div>
        <v-container fluid>

            <v-row align="center" class="mt-1">
                <v-col cols="6" md="4">
                </v-col>
                <!-- <v-col md="4" cols="6">

                </v-col>
                <v-col md="2" alig="end">
                    <v-select v-model="tipo_material" :items="tipos_material" item-text="text" item-value="id" outlined label="Tipo" hide-details></v-select>
                </v-col> -->
                <v-col align="end">
                    
                    <!-- <v-btn @click="mostrarModal()" color="primary" class="mr-2">AGREGAR 
                        <v-icon>mdi-plus</v-icon>
                    </v-btn> -->
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                AGREGAR 
                                <v-icon>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(tipo, index) in tipos_material"
                                :key="index"
                                @click="mostrarModal(tipo)"
                            >
                            <v-list-item-title>{{ tipo.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>

            <!-- <v-row v-if="actividades.length > 0" class="mt-2">
                <v-col cols="12" md="6" v-for="actividad in actividades" :key="actividad.id">
                    <v-card outlined>
                        <v-card-title class="headline">
                            {{ actividad.nombre }}
                        </v-card-title>

                        <v-card-subtitle class="pb-0">
                            <small>Fecha de Publicación: {{ actividad.created_at }}</small>
                        </v-card-subtitle>

                        <v-card-subtitle class="pt-0 mt-0">
                            <small>
                                <strong>Fecha de Entrega: {{ actividad.fecha_entrega }}</strong>
                            </small>
                        </v-card-subtitle>

                        <v-card-subtitle min-height="200" class="pt-0">
                            <p class="mb-2">
                                {{ actividad.descripcion }}
                            </p>
                        </v-card-subtitle>

                        <v-card-subtitle class="pt-0">
                            <v-row dense>
                                <v-col cols="6">
                                    <small>Adjuntos: </small>
                                    <br>
                                    <a>Documento.pdf</a>
                                </v-col>
                                <v-col cols="6">
                                    <small>Puntos: </small>
                                    <br>
                                    <h4>{{ actividad.punteo }}</h4>
                                </v-col>
                            </v-row>
                        </v-card-subtitle>

                        <v-divider class="mb-2"></v-divider>
                        <v-card-actions>
                            <v-btn @click="modal_editar(actividad.id)" icon color="primary">
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn @click="eliminar(actividad.id)" icon color="red">
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                            <v-btn @click="modal_calificar(actividad.id)" icon color="success">
                                <v-icon>
                                    mdi-account-group
                                </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row> -->


            <v-list class="mt-4">
                <v-list-group
                    v-for="item in tipos"
                    :key="item.id"
                    v-model="item.active"
                    :prepend-icon="item.icono"
                    no-action
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="headline">{{ item.text}}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.descripcion }}</v-list-item-subtitle>

                        </v-list-item-content>
                    </template>

                    <v-container fluid>
                        <v-row v-if="item.contenido.length > 0">
                            <v-col cols="12" md="6" v-for="actividad in item.contenido" :key="actividad.id">
                                <v-card outlined>
                                    <v-card-title class="headline">
                                        {{ actividad.nombre }}
                                    </v-card-title>

                                    <v-card-subtitle class="pb-0">
                                        <small>Fecha de Publicación: {{ actividad.created_at }}</small>
                                    </v-card-subtitle>

                                    <v-card-subtitle min-height="200" class="pt-0 mt-4">
                                        <p class="mb-2">
                                            {{ actividad.descripcion }}
                                        </p>
                                    </v-card-subtitle>

                                    <v-card-subtitle class="pt-0">
                                        <v-row dense>
                                            <v-col v-if="item.id == 'videos'" cols="12">
                                                <small>URL: </small>
                                                <br>
                                                <a :href="actividad.url" target="_blank">
                                                    {{ actividad.url }}
                                                </a>
                                            </v-col>

                                            <v-col v-if="item.id == 'documentos'" cols="12">
                                                <small>Adjunto: </small>
                                                <br>
                                                <a :href="api_url + actividad.id" download>
                                                    {{ actividad.nombre_archivo }}
                                                </a>
                                            </v-col>
                                            
                                        </v-row>
                                    </v-card-subtitle>

                                    <v-divider class="mb-2"></v-divider>
                                    <v-card-actions>
                                        <v-btn @click="modal_editar(actividad.id, item)" icon color="primary">
                                            <v-icon>
                                                mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                        <v-btn @click="eliminar(actividad, item)" icon color="red">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row class="mt-4 mb-10" align="center" justify="center" v-if="item.contenido.length <= 0">
                            <v-col cols="3" md="1">
                                <v-img
                                    src="@/assets/img/warn.png"
                                ></v-img>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <h1>Aún no se han registrado materiales de apoyo</h1>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-list-group>
            </v-list>


            <Modal @closeModal="modalShow = false" :titleModal="titleModal" :sizeModal="sizeModal" :modalShow="modalShow" ref="modal">
                <template #form-modal>
                    <FormVideo v-if="tipo_material == 'videos'" :idItem="idItem" :enableEdit="editMode" @updateTable="obtener_datos" @closeModal="modalShow = false"></FormVideo>

                    <FormDocumento v-if="tipo_material == 'documentos'"  :idItem="idItem" :enableEdit="editMode" @updateTable="obtener_datos" @closeModal="modalShow = false"></FormDocumento>
                </template>
            </Modal>

        </v-container>
    </div>
</template>

<script>

    import Modal from "@/components/Modal";
    import FormVideo from "@/components/material/FormVideo";
    import FormDocumento from "@/components/material/FormDocumento";

    import Swal from 'sweetalert2'

    export default {
        components: {
            Modal,
            FormVideo,
            FormDocumento
        },
        data(){
            return{
                actividades: [],
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                editMode: false,
                idItem: null,
                type: null,
                tipos_material: [
                    {
                        id: 'videos',
                        text: 'Videos'
                    },
                    {
                        id: 'documentos',
                        text: 'Documentos'
                    }
                ],
                tipo_material: null,
                tipos: [],
                api_url: process.env.VUE_APP_API_URL + 'descargar_adjunto_material/',
            }
        },
        methods: {

            obtener_datos(){

                let data = {

                    curso_id: this.$route.params.id,
                    tipo: this.tipo_material

                }

                this.axios.post(process.env.VUE_APP_API_URL + "material_curso_maestro", data)
                .then((response) => {
                    
                    console.log(response.data);
                    this.tipos = response.data

                })

            },
            mostrarModal(tipo){

                this.tipo_material = tipo.id
                this.titleModal = "Agregar Material de Apoyo"
                this.sizeModal = "800"
                this.editMode = false
                this.idItem = null
                this.modalShow = true                

            },
            modal_editar(id, item){

                console.log(id)
                
                this.tipo_material = item.id
                this.titleModal = "Editar Material de Apoyo"
                this.sizeModal = "800"
                this.editMode = true
                this.idItem = id
                this.modalShow = true

            },
            eliminar(material, item){

                console.log(material)
                console.log(item)

                let data = {

                    id: material.id,
                    tipo: item.id

                }

                Swal.fire({
                    title: '¿Está seguro?',
                    html: "<strong>Precaución: </strong>Una vez eliminado no se podrá recuperar!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, ELIMINAR!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        this.axios.post(process.env.VUE_APP_API_URL + "eliminar_material", data)
                        .then((response) => {

                            console.log(response.data)

                            if (response.data) {
                                
                                Swal.fire({
                                    title: 'Excelente!',
                                    text: 'El material a sido eliminada exitosamente',
                                    icon: 'success',
                                }).then(() => {

                                    this.obtener_datos()

                                })

                            }
                        
                        })

                    }
                })

            }

        },
        mounted(){

            this.obtener_datos()

        }
    }
</script>