<template>
    <div>
        <v-container fluid class="mt-4">
            <v-row class="mb-4" align="center">
                <v-col md="4">
                    <v-text-field hide-details v-model="busqueda" label="Buscar..." prepend-inner-icon="mdi-account-search" clearable outlined></v-text-field>
                </v-col>
                <v-col align="end">
                    <v-btn @click="mostrar_boleta()" color="success">BOLETA 
                        <v-icon>mdi-text-box-check</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="10"
                        class="elevation-2"
                        :search="busqueda"
                        :single-expand="single_expand"
                        :expanded.sync="expanded"
                        item-key="id"
                        show-expand
                    >
                    
                        <!-- <template v-slot:[`item.action`]="">

                            <v-btn icon xs color="secondary">
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>

                        </template> -->

                        <template v-slot:expanded-item="{ headers, item }">
                            
                            <td class="pt-10 pb-10 pl-10 pr-10" :colspan="headers.length" >

                                <v-card outlined class="mb-4" v-for="(unidad, key) in item.unidades" :key="key">
                                    <v-card-title>
                                        Unidad {{ unidad.id }}
                                        <v-spacer></v-spacer>
                                        Calificación: {{ unidad.calificacion }}
                                    </v-card-title>
                                    <v-card-text v-if="unidad.tareas.length > 0">

                                        <v-simple-table>
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Nombre
                                                    </th>
                                                    <th class="text-left">
                                                        Fecha de creación
                                                    </th>
                                                    <th class="text-left">
                                                        Fecha de entrega
                                                    </th>
                                                    <th class="text-left">
                                                        Bimestre
                                                    </th>
                                                    <th class="text-left">
                                                        Valor
                                                    </th>
                                                    <th class="text-left">
                                                        Estado
                                                    </th>
                                                    <th class="text-left">
                                                        Punteo Obtenido
                                                    </th>
                                                    <th class="text-left">
                                                        Acción
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="item in unidad.tareas"
                                                    :key="item.id"
                                                >
                                                    <td>{{ item.nombre }}</td>
                                                    <td>{{ item.created_at }}</td>
                                                    <td>{{ item.fecha_entrega }}</td>
                                                    <td>{{ item.bimestre }}</td>
                                                    <td>{{ item.punteo }}</td>
                                                    <td>

                                                        <v-chip dark :color="item.archivo ? 'success' : item.calificacion ? 'success' : 'red' " small label>
                                                            {{ item.archivo ? 'PRESENTADA' : item.calificacion ? 'PRESENTADA' : 'PENDIENTE' }}
                                                        </v-chip>

                                                    </td>
                                                    <td>{{ item.calificacion }}</td>
                                                    <td>
                                                        <v-btn :href="api_url + item.entrega_id" :disabled="!item.archivo" icon xs color="primary">
                                                            <v-icon>mdi-download</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>

                                    </v-card-text>

                                    <v-card-text class="text-center">

                                        <small v-if="unidad.tareas.length <= 0" >
                                            -- Aún no se han registrado actividades -- 
                                        </small>
                                    </v-card-text>
                                </v-card>

                            </td>
                            
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

            <Modal @closeModal="closeModal()" :modalShow="modalShow" :titleModal="titleModal" :sizeModal="sizeModal">
                <template #form-modal>
                    <FormPreview :id="id" :url="url"></FormPreview>
                </template>
            </Modal>

        </v-container>
    </div>
</template>

<script>

    import Modal from '@/components/Modal'
    import FormPreview from '@/components/FormPreview'

    export default {
        components: {
            Modal,
            FormPreview
        },
        data(){
            return{
                expanded: [],
                single_expand: false,
                data_url: process.env.VUE_APP_API_URL + "obtener_cursos_alumno",
                busqueda: null,
                headers: [],
                items: [],
                fab: false,
                breadcrumbs: [
                    {
                        text: 'Administración',
                        href: '#/home/administracion',
                    },
                    {
                        text: 'Usuarios',
                        active: true
                    },
                ],
                api_url: process.env.VUE_APP_API_URL + 'descargar_archivo/',
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                id: null,
                url: null
            }
        },
        methods: {

            obtener_datos(){

                let data = {
                    id_alumno: this.$route.params.id
                }

                this.axios.post(this.data_url, data)
                .then((response) => {
                    
                    console.log(response.data);
                    this.headers = response.data.headers
                    this.items = response.data.items

                })

            },
            descargar(item){

                console.log(item);

            },
            mostrar_boleta(){

                this.titleModal = "Pagos"
                this.sizeModal = "1200"
                this.id = parseInt(this.$route.params.id)
                this.url = 'boleta_calificaciones'
                this.modalShow = true


            },
            closeModal(){

                this.modalShow = false

            }

        },
        mounted(){

            this.obtener_datos()

        }            
    }
</script>