<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col v-for="curso in cursos" :key="curso.id" md="3" lg="3" cols="12">
                    <v-card rounded min-height="170" >

                        <v-container>
                            <v-row  style="height: 120px;" align="start" dense>
                                <v-col>
                                    <v-card-title>{{ curso.nombre }}</v-card-title>
                                    <v-card-subtitle>Catedratico(a): {{ curso.maestro  ? curso.maestro : 'Pendiente'}} </v-card-subtitle>
                                </v-col>
                            </v-row>

                            <v-row align="end" dense>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="6">
                                    <!-- <v-btn icon color="orange">
                                        <v-icon>
                                            mdi-bell
                                        </v-icon>
                                    </v-btn> -->
                                </v-col>
                                <v-col align="end" cols="6">
                                    <v-btn :to="{ name: 'curso_alumno', params: { id: curso.id } }" color="primary" small text>INGRESAR</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                        
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                cursos: []
            }
        },
        methods: {
            obtener_cursos(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {

                    id_alumno: usuario.id_persona

                }

                this.axios.post(process.env.VUE_APP_API_URL + "obtener_cursos_alumno", data)
                .then((response) => {
                    
                    console.log(response.data);

                    this.cursos = response.data.items

                })

            },
            
        },
        mounted(){

            this.obtener_cursos()

        }    
    }
</script>