<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12" md="3" v-for="(acceso) in accesos_admin" :key="acceso.text">
                    <CardAdmin :url="acceso.url" :text="acceso.text" :icon="acceso.icon"></CardAdmin>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

    import CardAdmin from '@/components/CardAdmin.vue'

    export default {
        components: {
            CardAdmin
        },
        data(){
            return{
                accesos_admin: [
                    {
                        icon: "key.png",
                        text: "Usuario",
                        url: 'usuarios'
                    },
                    {
                        icon: "team.png",
                        text: "Colaboradores",
                        url: 'colaboradores'
                    },
                    {
                        icon: "school.png",
                        text: "Sedes",
                        url: 'sedes'
                    },
                    {
                        icon: "schedule.png",
                        text: "Jornadas",
                        url: 'jornadas'
                    },
                    {
                        icon: "classroom.png",
                        text: "Grados",
                        url: 'grados_admin'
                    },
                    // {
                    //     icon: "receipt.png",
                    //     text: "Pagos",
                    //     url: 'pagos'
                    // },
                    // {
                    //     icon: "report-card.png",
                    //     text: "Boleta",
                    //     url: 'boleta'
                    // },
                    {
                        icon: "bulletin-board.png",
                        text: "Anuncios",
                        url: 'anuncios_admin'
                    },
                    // {
                    //     icon: "settings.png",
                    //     text: "Configuración",
                    //     url: 'anuncios'
                    // }
                ]
            }
        }    
    }
</script>