<template>
    <div>
        <v-row v-if="!enableEdit" align="center" justify="center" class="mt-4">
            <v-col md="3">
                <v-btn @click="btn_nuevo()" :outlined="!tipo_ingreso ? true : tipo_ingreso == 'N' ? false : true" color="primary" x-large block>
                    NUEVO 
                    <v-icon>mdi-account-plus</v-icon>
                </v-btn>
            </v-col>

            <v-col md="3">
                <v-btn @click="btn_existente()" :outlined="!tipo_ingreso ? true : tipo_ingreso == 'E' ? false : true" color="success" x-large block>
                    EXISTENTE 
                    <v-icon>mdi-account-arrow-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        

        <v-form v-model="valid" ref="form" @submit.prevent="!enableEdit ? registrar() : editar()" v-if="tipo_ingreso == 'N' || enableEdit">
            <v-row class="mt-2 mb-2">
                <v-col>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
                 <v-col cols="6">
                    <v-text-field v-model="encargado.nombre" :rules="[v => !!v || 'Campo obligatorio!']" hide-details autocomplete="off" label="Primer Nombre" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="encargado.segundo_nombre" hide-details autocomplete="off" label="Segundo Nombre" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="encargado.apellido" :rules="[v => !!v || 'Campo obligatorio!']" hide-details autocomplete="off" label="Primer Apellido" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="encargado.segundo_apellido" hide-details autocomplete="off" label="Segundo Apellido" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="encargado.telefono" hide-details autocomplete="off" label="Teléfono" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="encargado.direccion" hide-details autocomplete="off" label="Dirección" outlined></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field v-model="encargado.email" hide-details autocomplete="off" label="Email" outlined></v-text-field>
                </v-col>
            </v-row>

            <v-row class="mt-4" align="center" justify="center">
                <v-col align="center" cols="12">
                    <v-btn
						color="secondary"
						@click="() => { this.$emit('closeModal') }"
					>
						Cancelar
					</v-btn>

					<v-btn
						color="primary"
                        class="ml-2"
						type="submit"
					>
						Aceptar
					</v-btn>
                </v-col>
            </v-row>

        </v-form>

        <v-form @submit.prevent="registrar_existente()" v-model="valid" ref="form" v-if="tipo_ingreso == 'E'">
            <v-row class="mt-2 mb-2">
                <v-col>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-autocomplete :items="encargados" item-text="nombre" item-value="id" v-model="encargado.encargado_id" :rules="[v => !!v || 'Campo obligatorio!']" hide-details autocomplete="off" label="Seleccione un encargado" outlined></v-autocomplete>
                </v-col>
            </v-row>

            <v-row class="mt-4" align="center" justify="center">
                <v-col align="center" cols="12">
                    <v-btn
						color="secondary"
						@click="() => { this.$emit('closeModal') }"
					>
						Cancelar
					</v-btn>

					<v-btn
						color="primary"
                        class="ml-2"
						type="submit"
					>
						Aceptar
					</v-btn>
                </v-col>
            </v-row>

        </v-form>
    </div>
</template>

<script>

    import Swal from 'sweetalert2'
    
    export default {
        props: {
            enableEdit: Boolean,
            idItem: Number
        },
        data(){
            return{
                encargado: {
                    nombre: null,
                    segundo_nombre: null,
                    apellido: null,
                    segundo_apellido: null,
                    telefono: null,
                    direccion: null,
                    email: null,
                    sede_id: null,
                    alumno_id: null,
                    encargado_id: null
                },
                tipo_ingreso: null,
                valid: true,
                encargados: []
            }
        },
        methods: {

            btn_nuevo(){

                this.tipo_ingreso = 'N'

            },
            btn_existente(){

                this.tipo_ingreso = 'E'

                // Obtener los encargados de la sede
                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {

                    sede_id: usuario.sede_id

                }

                this.axios.post(process.env.VUE_APP_API_URL + "encargados_sede", data)
                .then((response) => {
                    
                    console.log(response.data)
                    this.encargados = response.data

                })

            },
            registrar(){

                this.$refs.form.validate()

                if (this.valid) {

                    let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                    this.encargado.sede_id = usuario.sede_id
                    this.encargado.alumno_id = this.$route.params.id

                    this.axios.post(process.env.VUE_APP_API_URL + "registrar_encargado", this.encargado)
                    .then((response) => {
                        
                        if (response.data) {
                            
                            Swal.fire({
                                title: 'Excelente!',
                                text: 'El encargado a sigo registrado exitosamente',
                                icon: 'success',
                            }).then(() => {
                                                                
                                this.$emit('closeModal')
                                this.$emit('updateTable') 

                            })

                        }

                    })

                }

            },
            editar(){

                this.$refs.form.validate()

                if (this.valid) { 

                    this.axios.post(process.env.VUE_APP_API_URL + "editar_encargado", this.encargado)
                    .then((response) => {
                        
                        if (response.data) {
                                
                            Swal.fire({
                                title: 'Excelente!',
                                text: 'El encargado a sido editada exitosamente',
                                icon: 'success',
                            }).then(() => {

                                this.$emit('closeModal')
                                this.$emit('updateTable') 

                            })

                        }

                    })

                }

            },
            obtener_detalle(){

                let data = {

                    id: this.idItem

                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_encargado", data)
                .then((response) => {
                    
                    this.encargado = response.data

                })


            },
            registrar_existente(){

                this.$refs.form.validate()

                if (this.valid) { 
                    
                    this.encargado.alumno_id = this.$route.params.id

                    this.axios.post(process.env.VUE_APP_API_URL + "registrar_existente", this.encargado)
                    .then((response) => {
                        
                        if (response.data) {
                            
                            Swal.fire({
                                title: 'Excelente!',
                                text: 'El encargado a sigo registrado exitosamente',
                                icon: 'success',
                            }).then(() => {
                                                                
                                this.$emit('closeModal')
                                this.$emit('updateTable') 

                            })

                        }

                    })

                }

            }

        },
        watch: {

            idItem: function(val){

                if (val) {
                    
                    this.obtener_detalle()

                }

            }

        },
        mounted(){

            if (this.enableEdit) {
                
                this.obtener_detalle()

            }

        }
    }
</script>