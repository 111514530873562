<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="8" md="6">
                    <h2>Ciclo Escolar: {{ ciclo_escolar }}</h2>
                </v-col>
                <v-col align="end">
                    <v-btn @click="mostrar_cambiar_ciclo()" icon color="secondary">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-card
                        class="mx-auto"
                    >
                    <v-list>
                        <v-list-group
                            v-for="item in grados"
                            :key="item.id"
                            v-model="item.active"
                            :prepend-icon="item.icono"
                            no-action
                        >
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.grado}}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.nivel }} / Sección {{ item.seccion }} / Jornada {{ item.jornada }} / Ciclo {{ item.ciclo_escolar }}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="mt-2 mb-2">
                                        <small>
                                            Sede: {{ item.sede }}
                                        </small>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>


                            <v-container class="mt-4 mb-4">
                                <v-row>
                                    <v-col v-for="curso in item.cursos" :key="curso.id" md="3" lg="3" sm="4" cols="12">
                                        <v-card rounded min-height="170" >

                                            <v-container>
                                                
                                                <!-- Lista de Grados -->

                                                <v-row  style="height: 100px;" align="start" dense>
                                                    <v-col>
                                                       
                                                        <h2>{{ curso.curso }}</h2>

                                                    </v-col>
                                                </v-row>

                                                <v-row align="end" dense>
                                                    <v-col cols="12">
                                                        <v-divider></v-divider>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <!-- <v-btn icon color="orange">
                                                            <v-icon>
                                                                mdi-message
                                                            </v-icon>
                                                        </v-btn> -->
                                                    </v-col>
                                                    <v-col align="end" cols="6">
                                                        <v-btn :to="{ name: 'curso_maestro', params: { id: curso.id } }" color="primary" small text>INGRESAR</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-list-group>
                    </v-list>
                    </v-card>
                </v-col>
            </v-row>

            <Modal @closeModal="modalShow = false" :modalShow="modalShow" :titleModal="titleModal" :sizeModal="sizeModal">
                <template #form-modal>
                    <FormCambiarCiclo @actualizar_ciclo="(ciclo) => {set_ciclo(ciclo)}" @closeModal="modalShow = false"></FormCambiarCiclo>
                </template>
            </Modal>

        </v-container>
    </div>
</template>

<script>

    import Modal from '@/components/Modal'
    import FormCambiarCiclo from '@/components/cursos/FormCambiarCiclo'

    export default {
        components: {
            Modal,
            FormCambiarCiclo
        },
        data(){
            return{
                cursos: [],
                grados: [],
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                ciclo_escolar: new Date().getFullYear()
            }
        },
        methods: {
            set_ciclo(ciclo){
                this.ciclo_escolar = ciclo
                this.obtener_cursos()
            },
            obtener_cursos(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {

                    empleado_id: usuario.id_persona,
                    ciclo_escolar: this.ciclo_escolar

                }

                this.axios.post(process.env.VUE_APP_API_URL + "cursos_maestro", data)
                .then((response) => {
                    
                    this.grados = response.data

                })

            },
            mostrar_cambiar_ciclo(){

                this.titleModal = "Cambiar Ciclo"
                this.sizeModal = "300"
                this.modalShow = true

            }
        },
        mounted(){

            this.obtener_cursos()

        }    
    }
</script>