<template>
    <div>
            <section id="about-me">
                <div class="py-12"></div>

                <v-container class="text-center">
                    <h2 class="display-2 font-weight-bold mb-3">CENTRO EDUCATIVO INMACULADA CONCEPCIÓN</h2>

                    <v-responsive
                        class="mx-auto mb-8"
                        width="56"
                    >
                        <v-divider class="mb-1"></v-divider>

                        <v-divider></v-divider>
                    </v-responsive>

                    <v-responsive
                        class="mx-auto title font-weight-light mb-8"
                        max-width="720"
                    >
                        27 avenida 31-39 Colonia Las Tapias Zona 28
                        <br>
                        Tel. 5981-2286
                    </v-responsive>
                </v-container>

               
            </section>
            <section
                id="features"
                class="grey lighten-3"
            >
                <div class="py-12"></div>

                <v-container class="text-center">
                <h2 class="display-2 font-weight-bold mb-3">SOBRE NOSOTROS</h2>

                <v-responsive
                    class="mx-auto mb-12"
                    width="56"
                >
                    <v-divider class="mb-1"></v-divider>

                    <v-divider></v-divider>
                </v-responsive>

                <v-row>
                    <v-col
                    v-for="({ icon, title, text }, i) in features"
                    :key="i"
                    cols="12"
                    md="6"
                    >
                    <v-card
                        class="py-12 px-4"
                        color="grey lighten-5"
                        flat
                        min-height="410"
                    >
                        <v-theme-provider dark>
                        <div>
                            <v-avatar
                            color="primary"
                            size="88"
                            >
                            <v-icon
                                large
                                v-text="icon"
                            ></v-icon>
                            </v-avatar>
                        </div>
                        </v-theme-provider>

                        <v-card-title
                        class="justify-center font-weight-black text-uppercase"
                        v-text="title"
                        ></v-card-title>

                        <v-card-text
                        class="subtitle-1"
                        v-text="text"
                        >
                        </v-card-text>
                    </v-card>
                    </v-col>
                </v-row>
                </v-container>

                <div class="py-12"></div>
            </section>

            <section>
                <div class="py-12"></div>
                 <v-container class="text-center">
                    <h2 class="display-2 font-weight-bold mb-3">JORNADAS</h2>

                    <v-responsive
                        class="mx-auto mb-8"
                        width="56"
                    >
                        <v-divider class="mb-1"></v-divider>

                        <v-divider></v-divider>
                    </v-responsive>

                    <v-responsive
                        class="mx-auto title font-weight-light mb-8"
                    >
                        <v-row align="center">
                            <v-col cols="6">
                                <v-img src="https://27mi124bz6zg1hqy6n192jkb-wpengine.netdna-ssl.com/wp-content/uploads/2020/05/10-Teaching-Strategies-to-Help-Students-Listen-scaled.jpg"></v-img>
                            </v-col>
                            <v-col>
                                <v-row align="center" justify="center" dense>
                                    <v-col cols="12">
                                        <h4>JORNADA MATUTINA</h4>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Básicos
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Bachillerato en Ciencias y Letras con Orientación en Computación
                                                </v-list-item-title>
                                               
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12">
                                        <h4>JORNADA FIN DE SEMANA - DÍAS SABADOS</h4>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Básicos
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Básicos por Madurez
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Bachillerato en Computación con Orientación Comercial
                                                </v-list-item-title>
                                                <v-list-item-title>
                                                    Bachillerato en Ciencias y Letras por Madurez
                                                </v-list-item-title>
                                                
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-responsive>
                </v-container>

            </section>
    </div>
        
</template>

<script>
    export default {
        data(){
            return{
                features: [
            {
                icon: 'mdi-bullseye-arrow',
                title: 'Misión',
                text: 'Promover oportunidades de aprendizaje, dirigidos  a la comunidad circundante, mediante hechos científico - tecnológicos; mientras  se incita el desarrollo de habilidades y destrezas,   con actitudes apegadas a los valores espirituales, filosóficos, sociales y conciencia ecológica; además de impulsar el deseo de continuar una educación superior, para el logro de una mayor competitividad personal, que hará mejores ciudadanos y una mejor nación.',
            },
            {
                icon: 'mdi-eye',
                title: 'Visión',
                text: 'Conformar una institución educativa orientada a la búsqueda de la excelencia académica, comprometida con el cumplimiento de la función docente, como un espacio estratégico, en un ámbito cultural vulnerable, que nos permita afrontar los desafíos del mundo globalizado y afectado;  con la responsabilidad de fortalecer la calidad permanente, la mejora continua de las estrategias pedagógicas-tecnológicas; partiendo de la formación y desarrollo de un maestro emprendedor, innovador, tecnificado y de amplio espíritu investigativo.',
            },
            // {
            //     icon: 'mdi-family-tree',
            //     title: 'Valores',
            //     text: 'Nuestro compromiso, la lealtad son dos pilares muy importantes y fundamentales que sobrepasan otras reglas sociales, y hace un grupo más efectivo a la hora de llevar a cabo las metas establecidas por el colegio.',
            // },
            ],
            }
        }
    }
</script>

<style>

</style>