<template>
	<div>
		<v-container>
			<v-form ref="form" v-model="valid" @submit.prevent="!idItem ? registrar() : editar()">
				<v-row class="mt-2">
					<v-col md="12">
						<!-- Datos del anuncio -->
						<v-row>
							<v-col md="6">
								<v-text-field :rules="[v => !!v]" autocomplete="off" v-model="anuncio.nombre" label="Nombre" outlined hide-details></v-text-field>
							</v-col>
							<v-col md="6">
								<v-textarea :rules="[v => !!v]" autocomplete="off" v-model="anuncio.descripcion" rows="4" label="Descripción" outlined hide-details></v-textarea>
							</v-col>
							<v-col md="6">
								<v-file-input autocomplete="off" accept="image/*" @change="select_image" v-model="portada" prepend-icon="" prepend-inner-icon="mdi-camera" label="Portada" outlined hide-details></v-file-input>
							</v-col>

							<v-col md="6">
								<v-file-input autocomplete="off" v-model="adjunto" prepend-icon="" prepend-inner-icon="mdi-file" label="Archivo Adjunto" outlined hide-details></v-file-input>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<v-row class="mt-4" align="center" justify="center">
					<v-col align="center" cols="12">
						<v-btn
							color="secondary"
							large
							@click="() => { this.$emit('closeModal') }"
						>
							Cancelar
						</v-btn>

						<v-btn
							color="primary"
							class="ml-2"
							large
							type="submit"
						>
							Aceptar
						</v-btn>
					</v-col>
				</v-row>
				
				<v-divider class="mt-4"></v-divider>
				<v-row class="mt-4" align="center" justify="center">
					<v-col
						cols="10"
					>	

						<v-card min-height="250">
							<v-card-title
								class="mb-2"
							>
								<h2>
									{{ anuncio.nombre ? anuncio.nombre : 'Título' }}
								</h2>
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col>
									
										<v-card-subtitle min-height="300">
											{{ anuncio.descripcion ? anuncio.descripcion : 'Descripción' }}
										</v-card-subtitle>
									</v-col>
									<v-col cols="4">
										<v-img height="165" contain :src="url_portada ? url_portada : anuncio.portada ? api + anuncio.portada : '/img/megaphone.png'"></v-img>
									</v-col>
								</v-row>
							</v-card-text>

							<v-divider class="mb-2"></v-divider>

							<v-card-actions>

								<v-btn
									icon
									small
								>
									<v-icon>
										mdi-download
									</v-icon>
								</v-btn>

								<v-spacer></v-spacer>
								<small>
									Fecha de Publicación: 10/02/2021
								</small>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-form>
		</v-container>
	</div>
</template>

<script>

	import Swal from 'sweetalert2'

	export default {
		props: {

			idItem: Number

		},
		data(){
			return{

				items: [
					{
						color: '#1F7087',
						src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
						title: 'Supermodel',
						artist: 'Foster the People',
					},
					{
						color: '#952175',
						src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
						title: 'Halcyon Days',
						artist: 'Ellie Goulding',
					},
				],
				anuncio: {

					nombre: null,
					descripcion: null,
					portada: null,
					adjunto: null,
					color: {},
					dark: null

				},
				portada: null,
				url_portada: null,
				adjunto: null,
				valid: true,
				api: process.env.VUE_APP_API_URL,

			}
		},
		methods: {

			select_image(){

				if (this.portada) {
					
					this.url_portada = URL.createObjectURL(this.portada)
					this.anuncio.portada = null

				}

			},
			registrar(){

				this.$refs.form.validate()

				if (this.valid) {
					
					const usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

					let formData = new FormData();

					formData.append('portada', this.portada);
					formData.append('adjunto', this.adjunto);
					formData.append('nombre', this.anuncio.nombre);
					formData.append('descripcion', this.anuncio.descripcion);
					formData.append('sede_id', usuario.sede_id);
					
					this.axios.post(process.env.VUE_APP_API_URL + "registrar_anuncio", formData)
					// eslint-disable-next-line no-unused-vars
					.then((response) => {

						if (response.data) {
							
							Swal.fire({
								title: 'Excelente!',
								text: 'El anuncio a sido publicado exitosamente',
								icon: 'success',
							}).then(() => {

								this.$emit('closeModal')
								this.$emit('updateTable') 

							})

						}

					})

				}

			},
			detalle(){

				const data = {

					id: this.idItem

				}

				this.axios.post(process.env.VUE_APP_API_URL + "detalle_anuncio", data)
				// eslint-disable-next-line no-unused-vars
				.then((response) => {
					this.anuncio = response.data
				})

			},
			editar(){

				this.$refs.form.validate()

				if (this.valid) {
					
					let formData = new FormData();

					formData.append('portada', this.portada);
					formData.append('adjunto', this.adjunto);
					formData.append('nombre', this.anuncio.nombre);
					formData.append('descripcion', this.anuncio.descripcion);
					formData.append('id', this.anuncio.id);
					
					this.axios.post(process.env.VUE_APP_API_URL + "editar_anuncio", formData)
					// eslint-disable-next-line no-unused-vars
					.then((response) => {

						if (response.data) {
							
							Swal.fire({
								title: 'Excelente!',
								text: 'El anuncio a sido editado exitosamente',
								icon: 'success',
							}).then(() => {

								this.$emit('closeModal')
								this.$emit('updateTable') 

							})

						}

					})

				}

			}

		},
		watch: {

			idItem: function(val){

				if (val) {
					
					this.detalle()

				}

			}

		},
		mounted(){

			if (this.idItem) {
				
				this.detalle()

			}

		}
	}
</script>

<style>

</style>