<template>
    <v-app>
        <v-app-bar
        app
        color="white"
        height="70"
        >
        <v-avatar
            class="mr-3"
            color="grey lighten-5"
            size="70"
            @click="home()"
            style="cursor: pointer"
        >
            <v-img
            contain
            max-height="70%"
            src="@/assets/img/school.png"
            
            ></v-img>
        </v-avatar>

        <!-- <v-toolbar-title class="font-weight-black headline">
            CORPORACIÓN EDUCATIVA SUPERIOR
        </v-toolbar-title> -->

        <v-spacer></v-spacer>

            <!-- <v-btn
                color="primary"
                dark
                text
                x-large
                v-if="$vuetify.breakpoint.mdAndUp"
                :to="{name: 'start'}"
            >
                NOSOTROS
            </v-btn> -->
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">

                    <v-btn
                        color="primary"
                        dark
                        text
                        x-large
                        v-if="$vuetify.breakpoint.mdAndUp"
                        v-bind="attrs"
                        v-on="on"
                    >
                        SEDES
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    :to="{name: item.to}"
                    >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

             <v-btn
                color="primary"
                dark
                
                text
                x-large
                v-if="$vuetify.breakpoint.mdAndUp"
                :to="{name: 'admisiones'}"
            >
                ADMISIONES
            </v-btn>

            <v-btn
                color="primary"
                dark
                
                text
                x-large
                :to="{ name: 'login' }"
                v-if="$vuetify.breakpoint.mdAndUp"
            >
                PLATAFORMA
            </v-btn>

            <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.mdAndUp" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-app-bar>
        
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
        >
            <v-list
                nav
                dense
            >
                <v-list-item-group
                active-class="deep-purple--text text--accent-4"
                >
                <v-list-item :to="{name: 'start'}">
                    <v-list-item-title>INICIO</v-list-item-title>
                </v-list-item>

                <v-list-group
                    :value="false"
                    no-action
                >
                    <template v-slot:activator>
                        <v-list-item-title>SEDES</v-list-item-title>
                    </template>


                    <v-list-item :to="{name: 'canalitos'}">
                        <v-list-item-title>CANALITOS</v-list-item-title>
                    </v-list-item>
                    <v-list-item  :to="{name: 'inco'}">
                        <v-list-item-title>INCO</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'piedra_parada'}">
                        <v-list-item-title>PIEDRA PARADA</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'liceo'}">
                        <v-list-item-title>LICEO CIENCIAS TÉCNICAS</v-list-item-title>
                    </v-list-item>

                </v-list-group>

                <v-list-item>
                    <v-list-item-title>ADMISIONES</v-list-item-title>
                </v-list-item>
                 <v-list-item :to="{name: 'login'}">
                    <v-list-item-title>PLATAFORMA</v-list-item-title>
                </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>


        <v-main v-if="isHome">
            <section id="hero">
                <v-row no-gutters>
                    <v-img
                        :max-height="'calc(60vh - ' + $vuetify.application.top + 'px)'"
                        src="@/assets/img/home_background.jpg"
                    >
                        <v-theme-provider dark>
                        <v-container>
                            <v-row
                            align="center"
                            :class="[$vuetify.breakpoint.smAndDown ? 'white--text' : 'white--text mt-10']"
                            justify="center"
                            >
                            <v-col
                                class="white--text text-center"
                                cols="12"
                                tag="h1"
                            >
                                <span
                                :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                                class="font-weight-light"
                                >
                                BIENVENIDO A
                                </span>

                                <br>

                                <span
                                :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                                class="font-weight-black"
                                >
                                CORPORACIÓN EDUCATIVA SUPERIOR
                                </span>

                            </v-col>

                            <!-- <v-btn
                                class="align-self-end"
                                fab
                                outlined
                                @click="$vuetify.goTo('#about-me')"
                            >
                                <v-icon>mdi-chevron-double-down</v-icon>
                            </v-btn> -->
                            </v-row>
                        </v-container>
                        </v-theme-provider>
                    </v-img>
                </v-row>
            </section>

            <section id="about-me">
                <div class="py-12"></div>

                <v-container class="text-center">
                <h2 class="display-2 font-weight-bold mb-3">SOBRE NOSOTROS</h2>

                <v-responsive
                    class="mx-auto mb-8"
                    width="56"
                >
                    <v-divider class="mb-1"></v-divider>

                    <v-divider></v-divider>
                </v-responsive>

                <v-responsive
                    class="mx-auto title font-weight-light mb-8"
                    max-width="720"
                >
                    Somos una institución educativa con la visión de formar alumnos con un desarrollo integral.
                </v-responsive>

                <!-- <v-avatar
                    class="elevation-12 mb-12"
                    size="128"
                >
                    <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
                </v-avatar>

                <div></div>

                <v-btn
                    color="grey"
                    href="https://vuetifyjs.com"
                    outlined
                    large
                >
                    <span class="grey--text text--darken-1 font-weight-bold">
                    Vuetify Documentation
                    </span>
                </v-btn> -->
                </v-container>

                <div class="py-12"></div>
            </section>

            <section
                id="features"
                class="grey lighten-3"
            >
                <div class="py-12"></div>

                <v-container class="text-center">
                <h2 class="display-2 font-weight-bold mb-3">LO QUE NOS CARACTERIZA</h2>

                <v-responsive
                    class="mx-auto mb-12"
                    width="56"
                >
                    <v-divider class="mb-1"></v-divider>

                    <v-divider></v-divider>
                </v-responsive>

                <v-row>
                    <v-col
                    v-for="({ icon, title, text }, i) in features"
                    :key="i"
                    cols="12"
                    md="4"
                    >
                    <v-card
                        class="py-12 px-4"
                        color="grey lighten-5"
                        flat
                    >
                        <v-theme-provider dark>
                        <div>
                            <v-avatar
                            color="primary"
                            size="88"
                            >
                            <v-icon
                                large
                                v-text="icon"
                            ></v-icon>
                            </v-avatar>
                        </div>
                        </v-theme-provider>

                        <v-card-title
                        class="justify-center font-weight-black text-uppercase"
                        v-text="title"
                        ></v-card-title>

                        <v-card-text
                        class="subtitle-1"
                        v-text="text"
                        >
                        </v-card-text>
                    </v-card>
                    </v-col>
                </v-row>
                </v-container>

                <div class="py-12"></div>
            </section>


            <!-- <section id="sedes">
                <div class="py-12"></div>

                <v-container>
                <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">SEDES</h2>

                <v-responsive
                    class="mx-auto mb-12"
                    width="56"
                >
                    <v-divider class="mb-1"></v-divider>

                    <v-divider></v-divider>
                </v-responsive>

                <v-row>
                    <v-col
                    v-for="({ src, text, title }, i) in articles"
                    :key="i"
                    cols="12"
                    md="4"
                    >
                    <v-img
                        :src="src"
                        class="mb-4"
                        height="275"
                        max-width="100%"
                    ></v-img>

                    <h3
                        class="font-weight-black mb-4 text-uppercase"
                        v-text="title"
                    ></h3>

                    <div
                        class="title font-weight-light mb-5"
                        v-text="text"
                    ></div>

                    <v-btn
                        class="ml-n4 font-weight-black"
                        text
                    >
                        Continue Reading
                    </v-btn>
                    </v-col>
                </v-row>
                </v-container>

                <div class="py-12"></div>
            </section> -->

            <v-sheet
                id="contact"
                color="#333333"
                dark
                tag="section"
                tile
            >
                <div class="py-12"></div>

                <v-container>
                <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Contáctanos</h2>

                <v-responsive
                    class="mx-auto mb-12"
                    width="56"
                >
                    <v-divider class="mb-1"></v-divider>

                    <v-divider></v-divider>
                </v-responsive>

                <v-theme-provider light>
                    <v-row>
                    <v-col cols="12">
                        <v-text-field
                        flat
                        label="Nombre*"
                        solo
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                        flat
                        label="Email*"
                        solo
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                        flat
                        label="Asunto*"
                        solo
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-textarea
                        flat
                        label="Mensaje*"
                        solo
                        ></v-textarea>
                    </v-col>

                    <v-col
                        class="mx-auto"
                        cols="auto"
                    >
                        <v-btn
                        color="accent"
                        x-large
                        >
                        Enviar
                        </v-btn>
                    </v-col>
                    </v-row>
                </v-theme-provider>
                </v-container>

                <div class="py-12"></div>
            </v-sheet>
        </v-main>

            <v-main v-if="!isHome">
                <router-view></router-view>
            </v-main>

        <v-footer
            class="justify-center"
            color="#292929"
            height="100"
        >
        <div class="title font-weight-light grey--text text--lighten-1 text-center">
            &copy; {{ (new Date()).getFullYear() }}
        </div>
        </v-footer>
    </v-app>

</template>

<script>
export default {
    data () {
      return {
            drawer: false,
            features: [
            {
                icon: 'mdi-home',
                title: 'Instalaciones',
                text: 'Las instalaciones del colegio responden a las necesidades educativas y físicas de nuestros alumnos. Contamos con salones amplios, ventilados y con buena iluminación, áreas para recreación y estudio para cada nivel.',
            },
            {
                icon: 'mdi-account-check',
                title: 'Personal Docente',
                text: 'Reclutamos cuidadosamente profesionales capaces y con experiencia que se comprometen a apoyar las prácticas docentes de nuestra institución. La calidad de enseñanza está asegurada, entre otros factores, por sus acreditaciones y su entrenamiento continuo.',
            },
            {
                icon: 'mdi-family-tree',
                title: 'Cultura Organizacional',
                text: 'Nuestro compromiso, la lealtad son dos pilares muy importantes y fundamentales que sobrepasan otras reglas sociales, y hace un grupo más efectivo a la hora de llevar a cabo las metas establecidas por el colegio.',
            },
            ],
        
            items: [
            { title: 'Colegio Sinai', to: 'canalitos' },
            // { title: 'Colegio Sinai Piedra Parada', to: 'piedra_parada' },
            { title: 'Centro Educativo Inmaculada Concepción', to: 'inco' },
            { title: 'Liceo de Ciencias Técnicas', to: 'liceo' },
        ],
      }
    },
    methods: {

        home(){

            if (this.$route.name != 'start') {
                
                this.$router.push({name: 'start'})
                
            }
            
        }
    },
    computed: {
        isHome: function(){

            if (this.$route.name == 'start') {
                
                return true

            }

            return false

        }
    }
}
</script>

<style>

</style>