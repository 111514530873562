<template>
	<div>
		<v-form ref="form" v-model="valid" @submit.prevent="!idItem ? registrar() : editar()">
			<v-row class="mt-4">
				<v-col md="12">
					<v-text-field autocomplete="off" :rules="[v => !!v]" v-model="video.nombre" outlined hide-details label="Nombre"></v-text-field>
				</v-col>
				<v-col md="12">
					<v-textarea autocomplete="off" :rules="[v => !!v]" v-model="video.descripcion" outlined hide-details label="Descripción"></v-textarea>
				</v-col>
				<v-col md="12">
					<v-text-field autocomplete="off" :rules="[v => !!v]" v-model="video.url" outlined hide-details label="URL"></v-text-field>
				</v-col>
			</v-row>

			<v-row class="mt-4" align="center" justify="center">
                <v-col align="center" cols="12">
                    <v-btn
						color="secondary"
						@click="() => { this.$emit('closeModal') }"
					>
						Cancelar
					</v-btn>

					<v-btn
						color="primary"
                        class="ml-2"
						type="submit"
					>
						Aceptar
					</v-btn>
                </v-col>
            </v-row>

		</v-form>
	</div>
</template>

<script>

	import Swal from 'sweetalert2'

	export default {
		props: {
            enableEdit: Boolean,
            idItem: Number
        },
		data(){
			return{
				valid: true,
				video: {
					nombre: null,
					descripcion: null,
					url: null
				}
			}
		},
		methods: {

			registrar(){

				this.$refs.form.validate()

				if (this.valid) {
					
					this.video.curso_id = this.$route.params.id

					this.axios.post(process.env.VUE_APP_API_URL + "registrar_video", this.video)
                    .then((response) => {
						console.log(response.data);

						if (response.data) {
							
							Swal.fire({
								title: 'Excelente!',
								text: 'El material de apoyo a sido registrado exitosamente',
								icon: 'success',
							}).then(() => {
																
								this.$emit('closeModal')
								this.$emit('updateTable') 

							})

						}
					})

				}
				
			},
			obtener_detalle(){

				let data = {

                    id: this.idItem

                }

                this.axios.post(process.env.VUE_APP_API_URL + "detalle_video", data)
                .then((response) => {
                    
                    this.video = response.data

                })

			},
			editar(){

				this.$refs.form.validate()

				if (this.valid) {
					
					this.axios.post(process.env.VUE_APP_API_URL + "editar_video", this.video)
                    .then((response) => {
						console.log(response.data);

						if (response.data) {
							
							Swal.fire({
								title: 'Excelente!',
								text: 'El material de apoyo a sido actualizado exitosamente',
								icon: 'success',
							}).then(() => {
																
								this.$emit('closeModal')
								this.$emit('updateTable') 

							})

						}
					})

				}

			}

		},
		watch: {

            idItem: function(val){

                if (val) {
                    
                    this.obtener_detalle()

                }

            }

        },
        mounted(){

            if (this.idItem) {
                
                this.obtener_detalle()

            }

        }
	}
</script>

<style>

</style>