<template>
    <div>
        <v-dialog @click:outside="() => { this.$emit('closeModal') }" @keydown.esc="closeModal" v-model="modalShow" :width="sizeModal">
            
            <!-- Cuerpo del modal -->
        
			<v-card>
				<v-card-title class="headline grey lighten-2">
					{{ titleModal }}
				</v-card-title>
				
				<v-card-text>
					<slot name="form-modal"></slot>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
				</v-card-actions>

			</v-card>

        </v-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            modalShow: {
                type: Boolean,
                default: false
            },
            titleModal: String,
            sizeModal: String
        },
        data(){
            return{
                show: false
            }
        },
        methods: {
            closeModal(){

                this.$emit('closeModal')

            }
        },
        watch: {

            modalShow(val){

                this.show = val

            }

        }        
    }
</script>