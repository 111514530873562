<template>
    <div>
        <v-row>
            <v-col cols="12">
                <Chart></Chart>
            </v-col>
            <v-col cols="12">
                <ChartJornada></ChartJornada>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    import Chart from '@/components/reportes/Chart'
    import ChartJornada from '@/components/reportes/ChartJornada'

    export default {
        components: {
            Chart,
            ChartJornada
        }
    }
</script>