<template>
    <div>
            <section id="about-me">
                <div class="py-12"></div>

                <v-container class="text-center">
                    <h2 class="display-2 font-weight-bold mb-3">CANALITOS ZONA 24</h2>

                    <v-responsive
                        class="mx-auto mb-8"
                        width="56"
                    >
                        <v-divider class="mb-1"></v-divider>

                        <v-divider></v-divider>
                    </v-responsive>

                    <v-responsive
                        class="mx-auto title font-weight-light mb-8"
                        max-width="720"
                    >
                        Somos una institución educativa con la visión de formar alumnos con un desarrollo integral.
                    </v-responsive>
                </v-container>

                
            </section>
            <section>
                <div class="py-12"></div>
                <v-container class="text-center">
                    <h2 class="display-2 font-weight-bold mb-3">HISTORIA</h2>

                    <v-responsive
                        class="mx-auto mb-8"
                        width="56"
                    >
                        <v-divider class="mb-1"></v-divider>

                        <v-divider></v-divider>
                    </v-responsive>

                    <v-responsive
                        class="mx-auto title font-weight-light mb-8"
                    >
                        <v-row>
                            <v-col>
                                <v-img src="https://www.gettingsmart.com/wp-content/uploads/2017/01/Teacher-Helping-Young-Students-at-Table-Feature-Image.jpg">

                                </v-img>
                            </v-col>
                            <v-col>
                                <p>
                                    El colegio abre sus puestas en el año 2002 en la 9 avenida 04-31, zona 19 Colonia La Florida, donde se solicita autorización de funcionamiento para impartir ciclo Básico o Cultura General y Diversificado (Perito Contador con Orientación en Computación), en las jornadas matutina, vespertina y fin de semana, además ciclo básico por madurez y bachillerato en ciencias y letras por madurez, jornada fin de semana.  Sin embargo el número de alumnos inscritos fue muy bajo y se determinó por trasladarlos a otros establecimientos.
                                </p>
                                <p>
                                    La señora Carmen Priscila Castañeda Ramírez, fue la primera dueña del establecimiento, que luego decide vender la resolución de autorización del colegio al Profesor de Enseñanza Media Carlos Humberto Barrios López, quien decide su reubicación en la manzana 33 lote 6 Cantón Central de la Colonia Canalitos zona 24, de la ciudad capital de Guatemala.
                                </p>
                            </v-col>
                        </v-row>
                    </v-responsive>
                </v-container>
            </section>
            <section
                id="features"
                class="grey lighten-3"
            >
                <div class="py-12"></div>

                <v-container class="text-center">
                <h2 class="display-2 font-weight-bold mb-3">SOBRE NOSOTROS</h2>

                <v-responsive
                    class="mx-auto mb-12"
                    width="56"
                >
                    <v-divider class="mb-1"></v-divider>

                    <v-divider></v-divider>
                </v-responsive>

                <v-row>
                    <v-col
                    v-for="({ icon, title, text }, i) in features"
                    :key="i"
                    cols="12"
                    md="6"
                    >
                    <v-card
                        class="py-12 px-4"
                        color="grey lighten-5"
                        flat
                        min-height="410"
                    >
                        <v-theme-provider dark>
                        <div>
                            <v-avatar
                            color="primary"
                            size="88"
                            >
                            <v-icon
                                large
                                v-text="icon"
                            ></v-icon>
                            </v-avatar>
                        </div>
                        </v-theme-provider>

                        <v-card-title
                        class="justify-center font-weight-black text-uppercase"
                        v-text="title"
                        ></v-card-title>

                        <v-card-text
                        class="subtitle-1"
                        v-text="text"
                        >
                        </v-card-text>
                    </v-card>
                    </v-col>
                </v-row>
                </v-container>

                <div class="py-12"></div>
            </section>
    </div>
        
</template>

<script>
    export default {
        data(){
            return{
                features: [
            {
                icon: 'mdi-bullseye-arrow',
                title: 'Misión',
                text: 'Somos una Institución Educativa formadora de estudiantes en Educación Primaria, Educación Básica y Diversiticado, respaldados con personal docente competente, comprometidos en brindar una educacion eficaz y eficiente, para fortalecer el nivel de educación básica en beneficio de la sociedad.',
            },
            {
                icon: 'mdi-eye',
                title: 'Visión',
                text: 'Ser una institución educativa que sea reconocida y competitiva, acorde a los requerimientos de calidad que demanda la sociedad del conocimiento.',
            },
            // {
            //     icon: 'mdi-family-tree',
            //     title: 'Valores',
            //     text: 'Nuestro compromiso, la lealtad son dos pilares muy importantes y fundamentales que sobrepasan otras reglas sociales, y hace un grupo más efectivo a la hora de llevar a cabo las metas establecidas por el colegio.',
            // },
            ],
            }
        }
    }
</script>

<style>

</style>