<template>
	<div>
		<v-row align="center" justify="center" class="mt-4">
			<v-col align="center">

				<iframe height="700" width="1100" :src="pdf"></iframe>

			</v-col>
		</v-row>
	</div>
</template>

<script>
	export default {
		props: {
			url: String,
			id: Number
		},
		data(){
			return{
				api: process.env.VUE_APP_API_URL,
				pdf: null
			}
		},
		methods: {

			obtener_documento(){

				const data = {
					id: this.id
				}

				this.axios.post(process.env.VUE_APP_API_URL + this.url, data)
				.then((response) => {
					this.pdf = this.api + response.data.pdf_url	
				})

			}

		},
		watch: {

			url: function(){

				this.obtener_documento()

			}
		},
		mounted(){

			this.obtener_documento()

		}
	}
</script>

<style>

</style>