<template>
    <div>
        <v-row align="center" justify="center" class="mt-4">
            <v-col cols="6">
                <v-select v-model="tipo_pago" :items="tipos_pagos" item-text="text" item-value="id" outlined label="Tipo de Pago"></v-select>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <FormMensualidad @closeModal="closeModal" @updateTable="updateTable" :alumno="alumno" v-if="tipo_pago == 'mensualidades'"></FormMensualidad>
                <FormOtros @closeModal="closeModal" @updateTable="updateTable" :alumno="alumno" v-if="tipo_pago == 'otros'"></FormOtros>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    import FormMensualidad from '@/components/pagos/FormMensualidad.vue'
    import FormOtros from '@/components/pagos/FormOtros.vue'

    export default {
        props: {
            alumno: Object
        },
        components: {
            FormMensualidad,
            FormOtros
        },
        data(){
            return{
                tipos_pagos: [
                    {
                        text: "Mensualidades e Inscripción",
                        id: 'mensualidades'
                    },
                    {
                        text: 'Otros Pagos',
                        id: 'otros'
                    }
                ],
                tipo_pago: null
            }
        },
        methods: {

            closeModal(){

                this.$emit('closeModal')

            },
            updateTable(){

                this.$emit('updateTable')

            }
        }   
    }
</script>