<template>
    <div>

        <v-container fluid>

            <v-row class="mb-4" align="center">
                <v-col md="4">
                    <v-text-field autocomplete="off" hide-details v-model="busqueda" label="Buscar..." prepend-inner-icon="mdi-account-search" clearable outlined></v-text-field>
                </v-col>
                <v-col align="end">
                    <v-btn @click="mostrarModal()" color="primary">AGREGAR 
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

                
                <v-row class="mt-4" >
					<v-col
						md="4"
                        v-for="(anuncio, key) in anuncios"
                        :key="key"
					>	

						<v-card min-height="310" class="flexcard">
                            <v-card-title
                                class="mb-2"
                            >
                                <h3>
                                    {{ anuncio.nombre }}
                                </h3>
                            </v-card-title>
							<v-card-text>
								<v-row>
									<v-col>
										<v-card-subtitle>
											{{ anuncio.descripcion }}
										</v-card-subtitle>
									</v-col>
									<v-col cols="4">
                                        
										<v-img height="165" contain :src="anuncio.portada ? api + anuncio.portada : '/img/megaphone.png'">

                                            <v-card-title v-if="anuncio.portada" class="align-center fill-height justify-center" primary-title>
                                                <v-btn @click="agrandar(anuncio.portada)" icon x-large>
                                                    <v-icon>
                                                        mdi-magnify-plus
                                                    </v-icon>
                                                </v-btn>
                                            </v-card-title>
                                        </v-img>
									</v-col>
								</v-row>

							</v-card-text>
                            
							<v-divider class="mb-2"></v-divider>

							<v-card-actions class="card-actions">
									
								<v-btn
									icon
									small
                                    :disabled="!anuncio.adjunto"
                                    :href="api_url + anuncio.id"
								>
									<v-icon>
										mdi-download
									</v-icon>
								</v-btn>

                                <v-btn
									icon
                                    color="primary"
									small
                                    @click="mostrarEditar(anuncio.id)"
								>
									<v-icon>
										mdi-pencil
									</v-icon>
								</v-btn>

                                <v-btn
									icon
                                    color="red"
									small
                                    @click="eliminar(anuncio.id)"
								>
									<v-icon>
										mdi-delete
									</v-icon>
								</v-btn>

								<v-spacer></v-spacer>
								<small>
									Fecha de Publicación: {{ anuncio.created_at }}
								</small>
							</v-card-actions>


						</v-card>
					</v-col>
				</v-row>


            <Modal @closeModal="closeModal()" :modalShow="modalShow" :titleModal="titleModal" :sizeModal="sizeModal">
                <template #form-modal>
                    <Form :enableEdit="enableEdit" :idItem="idItem" @updateTable="obtener_datos()" @closeModal="closeModal()"></Form>
                </template>
            </Modal>

            <Modal @closeModal="modalShowImg = false" :modalShow="modalShowImg" :titleModal="titleModal" :sizeModal="sizeModal">
                <template #form-modal>
                    <v-img height="500" contain :src="api + imgLarge"></v-img>
                </template>
            </Modal>

        </v-container>
        
    </div>
</template>

<script>

    import Modal from '@/components/Modal.vue'
    import Form from '@/components/anuncios/Form.vue'

    import Swal from 'sweetalert2'

    export default {
        components: {
            Modal,
            Form  
        },
        data(){
            return{
                data_url: process.env.VUE_APP_API_URL + "obtener_anuncios",
                busqueda: null,
                modalShow: false,
                titleModal: null,
                sizeModal: null,
                enableEdit: false,
                idItem: null,
                anuncios: [],
                api: process.env.VUE_APP_API_URL,
                api_url: process.env.VUE_APP_API_URL + 'descargar_anuncio/',
                modalShowImg: false,
                imgLarge: null
            }
        },
        methods: {

            obtener_datos(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {
                    sede_id: usuario.sede_id
                }

                this.axios.post(this.data_url, data)
                .then((response) => {
                    
                    console.log(response.data)
                    this.anuncios = response.data

                })

            },
            mostrarModal(){

                this.titleModal = "Crear Anuncio"
                this.sizeModal = "1200"
                this.enableEdit = false
                this.idItem = null
                this.modalShow = true

            },
            closeModal(){

                this.modalShow = false

            },
            mostrarEditar(id){
                
                this.titleModal = "Editar Anuncio"
                this.sizeModal = "1200"
                this.enableEdit = true
                this.idItem = id
                this.modalShow = true

            },
            eliminar(id){

                let data = {

                    id: id

                }

                Swal.fire({
                    title: '¿Está seguro?',
                    text: "Una vez eliminado no se podrá recuperar!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, ELIMINAR!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        this.axios.post(process.env.VUE_APP_API_URL + "eliminar_anuncio", data)
                        .then((response) => {

                            if (response.data) {
                                
                                Swal.fire({
                                    title: 'Excelente!',
                                    text: 'El anuncio a sido eliminado exitosamente',
                                    icon: 'success',
                                }).then(() => {

                                    this.obtener_datos()

                                })

                            }
                        
                        })

                    }
                })

            },
            agrandar(img){

                this.sizeModal = "500"
                this.modalShowImg = true
                this.imgLarge = img

            },
            descargar(){

                

            }

        },
        mounted(){

            this.obtener_datos()

        }     
    }
</script>