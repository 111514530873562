<template>
    <div>
        <v-row class="mt-4" align="center" justify="center">
            <v-col cols="6">
                <v-text-field autocomplete="off" v-model="ciclo" hide-details outlined label="Ciclo Escolar"></v-text-field>
            </v-col>
            <v-col cols="1">
                <v-btn @click="buscar_grados()" icon>
                    <v-icon>
                        mdi-magnify
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-col>
                <v-card>
                    <v-card-text>

                        <v-list>
                            <v-list-group dense v-for="(nivel) in niveles" :key="nivel.id" :prepend-icon="nivel.icono">
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="nivel.nombre"></v-list-item-title>
                                    </v-list-item-content>
                                </template>

                                <v-list-item
                                    v-for="grado in nivel.grados"
                                    :key="grado.id"
                                >   
                                    <v-list-item-icon>
                                        <v-btn :color="grado.select ? 'success' : 'secondary'" @click="seleccionar_grado(grado)" text>
                                            <v-icon>
                                                {{ grado.select ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline' }}
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="grado.nombre"></v-list-item-title>
                                        <v-list-item-subtitle style="text-transform: capitalize">Sección: {{ grado.seccion }} - Jornada: {{ grado.jornada }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list-group>
                        </v-list>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-4" align="center" justify="center">
            <v-col align="center" cols="12">
                <v-btn
                    color="secondary"
                    @click="() => { this.$emit('closeModal') }"
                >
                    Cancelar
                </v-btn>

                <v-btn
                    color="primary"
                    class="ml-2"
                    :disabled="!gradoSelect"
                    @click="asignar()"
                >
                    Aceptar
                </v-btn>
            </v-col>
        </v-row>

    </div>
</template>

<script>

    import Swal from 'sweetalert2'

    export default {
        data(){
            return{

                ciclo: null,
                niveles: [],
                gradoSelect: false

            }
        },
        methods: {

            buscar_grados(){

                let usuario = JSON.parse(localStorage.getItem('plataforma-sinai'))

                let data = {
                    ciclo: this.ciclo,
                    sede_id: usuario.sede_id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "grados_inscripcion", data)
                .then((response) => {
                    
                    this.niveles = response.data
                    
                })

            },
            seleccionar_grado(gradoSelect){

                this.niveles.forEach(nivel => {
                    nivel.grados.forEach(grado => {
                        
                        if (grado.id != gradoSelect.id) {
                            grado.select = false
                        }
                    });
                });

                gradoSelect.select = !gradoSelect.select

                if (gradoSelect.select) {
                    
                    this.gradoSelect = gradoSelect.id

                }else{

                    this.gradoSelect = null

                }

            },
            asignar(){

                let data = {
                    grado_id: this.gradoSelect,
                    id_alumno: this.$route.params.id
                }

                this.axios.post(process.env.VUE_APP_API_URL + "asignar_grado", data)
                .then((response) => {
                    
                    if (response.data) {
                        
                        Swal.fire({
                            title: 'Excelente!',
                            text: 'El alumno a sido asignado al grado exitosamente',
                            icon: 'success',
                        }).then(() => {

                            this.$emit('actualizarDetalle')
                            this.$emit('closeModal')

                        })

                    }

                })

            }

        }
    }
</script>